import React, { memo, useState, useEffect, useCallback } from 'react'
// import { ZoomMtg } from "@zoomus/websdk"
import Zoom from '../classes/components/Zoom'
import { useSelector } from 'react-redux'
import { useManualQuery, useMutation } from 'graphql-hooks'
import { useParams } from 'react-router-dom'
import {
	Avatar,
	Button,
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { timestampToDateTime } from '@helpers'
import { Style } from '@common/style'
import { FETCH_SINGLE_WEBINAR, USER_ENROLL_MUTATION } from '@gql/webinar'
import { SnackBar } from '@common/components'

const useStyles = makeStyles({
  ended: {
		background: '#3e3e3e',
    padding: '8px 16px',
    color: '#ababab',
    width: 'fit-content'
  },
  webinarCard: {
  	width: 'fit-content'
  }
})

const JoinWebinar = () => {
	const styles = useStyles()
	const classes = Style()
	const { webinarId } = useParams()
	const state = useSelector(state => state)
	const [getWebinar] = useManualQuery(FETCH_SINGLE_WEBINAR)
	const [userEnroll] = useMutation(USER_ENROLL_MUTATION)
	const [joinWebinar, setJoinWebinar] = useState(false)
	// const [loading, setLoading] = useState(false)
	const [meeting, setMeeting] = useState(false)
	const [open, setOpen] = useState(false)
	const [messages, setMessages] = useState('')

	const fetchWebinar = useCallback(() => {
    // setLoading(true)
		getWebinar({
			variables: {
				_id: webinarId
			}
		}).then( ({data, error}) => {
			if(error) {
				// setMessages('Error fetching courses. Please try again.')
				// setOpen(true)
			} else {
				setMeeting(data.webinar)
				// setLoading(false)
			}
		})
  }, [getWebinar, webinarId])

	useEffect(() => {
		fetchWebinar()
	}, [fetchWebinar])

	const handleEnroll = (webinarId) => {
		userEnroll({
			variables: {
				_id: webinarId,
				email: state.user.user.email
			}
		}).then( ({data, error}) => {
			setMessages(`You have enrolled to this webinar`)
			setOpen(true)
		})
	}

	const handleClose = (event, reason) => {
		setOpen(false)
	}

	const WebinarInfo = ({ data }) => {
		return (
			<Card className={styles.webinarCard}>
				<CardHeader
					avatar={<Avatar src={data && data.instructor.profile_img} />}
					title={`${data && data.instructor.title} ${data && data.instructor.fullname} `}
				/>
				<CardContent>
					<Typography variant="h6">{data && data.name}</Typography>
					<div>
						<Typography variant="body2" gutterBottom>{timestampToDateTime(data && data.tm_started)}</Typography>
						<Typography variant="body2" gutterBottom>{data && data.enrolls.length} enrolled</Typography>
					</div>
				</CardContent>
				<CardActions>
					{data && data.tm_started + 10 * 60000 < new Date().getTime() ? 
						<Typography variant="body2" className={styles.ended}>This webinar is over</Typography>
						: data && data.enrolls.includes(state.user.user.email) ? <Button variant="contained" color="primary" fullWidth onClick={() => setJoinWebinar(true)}>Join webinar</Button> : <Button variant="contained" color="primary" fullWidth onClick={() => handleEnroll(data && data._id)}>Enroll webinar</Button>
					}
				</CardActions>
			</Card>
		)
	}

  return (
    <div>
      <div style={{marginTop: '65px'}} />
      <div className={classes.content}>
        <div className="zoom">
          {joinWebinar ? <Zoom meeting={meeting} /> : <WebinarInfo data={meeting} />}
        </div>
      </div>
      <SnackBar messages={messages} open={open} close={handleClose} />
    </div>
  )
}

export default memo(JoinWebinar)
