const initialState = {
  courses: []
}

const courses = (state = initialState, { type, payload }) => {
  switch (type) {
  	case "FETCH_ALL_COURSES":
      return {
        ...state,
        courses: payload
      }

    default:
      return state
  }
}

export {
	courses
}
