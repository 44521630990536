import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { NavLink } from 'react-router-dom'
import Toolbar from '@material-ui/core/Toolbar'
import Header from './header'
import Footer from './footer'

const useStyles =  makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#141414',
      height: '100%',
      color: '#fff'
    },
    wrapper: {
      flex: 1,
      // paddingTop: theme.spacing(3),
      // paddingBottom: theme.spacing(3),
    },
    loginWrapper: {
      position: 'relative',
      // backgroundColor: '#000',
      zIndex: 9
    },
    loginButton: {
      position: 'absolute',
      top: '16px',
      right: '16px',
      zIndex: 1000,
      padding: '6px 16px',
      backgroundColor: '#00916e',
      fontSize: '0.875rem',
      color: '#fff',
      fontWeight: '500',
      lineHeight: '1.75',
      borderRadius: '4px',
      boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',

      "&:hover": {
        backgroundColor: '#00916e'
      }
    },
    logo: {
      display: 'block',
      margin: '16px',
      background: 'url(/assets/logo4.png)',
      width: '188px',
      height: '30px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat'
    }
  })
)

const LoginHeader = ({signin}) => {
  const classes = useStyles()

  return (
    <div className={classes.loginWrapper}>
      <NavLink to='/' className={classes.logo}></NavLink>
      <NavLink to='/login' variant="contained" color="primary" className={classes.loginButton}>Sign In</NavLink>
    </div>
  )
}

const Layout = ({children, header, footer, signin }) => {
  const classes = useStyles()
  
  return (
    <div className={['body-root', classes.root].join(' ')}>
      { (header) ? <Header /> : <LoginHeader />}
      <Toolbar id="back-to-top-anchor" />
      <div className={classes.wrapper}>
        { children }
      </div>
      { (footer) ? <Footer /> : ''}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default memo(Layout);