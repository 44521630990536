import React, { memo } from 'react'
import { NavLink } from 'react-router-dom'
import { Button, Typography, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
// import YouTube from 'react-youtube'

const Style = makeStyles((theme) => ({
	featured: {
    margin: '56px 0',
    padding: '0 32px'
  },
  featuredContent: {
    padding: '0 32px'
  },
  video: {
    width: "100% !important",
    height: "auto !important",
  },
  youtubeFrame: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%"
  },
  learnMore: {
    display: 'inline-flex',    
    padding: '12px 24px',
    backgroundColor: 'rgba(109,109,110,0.7)',
    color: '#fff',
    borderRadius: '4px'
  },
}))

const FeaturedLeft = ({ data }) => {
	const styles = Style()

	return (
		<div className={styles.featured}>
      <Typography variant="h5" gutterBottom>Featured Course</Typography>
      
      <Grid container spacing={4}>
        <Grid item xs={12} sm={5}>
          <video src={`/media/${data?.trailer_video}`} controls controlsList="nodownload" type="video/mp4" className={styles.video} playsInline />
        </Grid>
        <Grid item xs={12} sm={7}>
          <div className={styles.featuredContent}>
            <Typography variant="h4" gutterBottom>{data?.name}</Typography>
            <Typography variant="subtitle1" gutterBottom dangerouslySetInnerHTML={{__html: data?.summary}}></Typography>
            <Button component={NavLink} to={`/class/${data?._id}`} variant="contained" color="primary" className="button">Start course</Button>
          </div>
        </Grid>
      </Grid>
    </div>
	)
}

export default memo(FeaturedLeft)