import { makeStyles } from '@material-ui/core/styles'

const UserProfile = makeStyles((theme) => ({
	memberSince: {
    padding: '4px 12px',
    margin: '0 16px',
    backgroundColor: '#313131',
    borderRadius: '4px'
  },
}))

export default UserProfile

