import React, { memo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from 'graphql-hooks'
import { makeStyles } from '@material-ui/core/styles'
import {
  Avatar,
  Container,
  CssBaseline,
  Typography
} from '@material-ui/core'
import { LockOutlined } from '@material-ui/icons'
import { USER_FORGOT_PASSWORD_MUTATION } from '@gql/user'
import { ForgotPassword } from '@common/components'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100% - 64px)',
    display: 'flex',
    alignItems: 'center'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  }
}))

const Forgot = () => {
  const styles = useStyles()
  const history = useHistory()
  const [userForgotPassword] = useMutation(USER_FORGOT_PASSWORD_MUTATION)
  const [loading, setLoading] = useState(false)
  const [messages, setMessages] = useState('')
  const [values, setValues] = useState({ email: '' })

  const handleInputChange = e => {
    const {name, value} = e.target
    setValues({...values, [name]: value})
  }

  const userTriggerForgotPassword = () => {
    setLoading(true)

    userForgotPassword({
      variables: {
        email: values.email,
      }
    }).then( ({data, error}) => {
      if (data?.forgotPassword === null) {
        const { message } = error.graphQLErrors[0]
        setMessages(message)
        setLoading(false)
        return
      }

      setLoading(false)
      history.push('/reset')
    })
  }

  return (
    <div className={styles.root}>
      <CssBaseline />
      <Container component="main" maxWidth="xs">
        <div className={styles.paper}>
          <Avatar className={styles.avatar}>
            <LockOutlined />
          </Avatar>
          <Typography component="h1" variant="h5">Forgot Password</Typography>
          <ForgotPassword handleInputChange={handleInputChange} userTriggerForgotPassword={userTriggerForgotPassword} messages={messages} loading={loading} values={values} showLogin={true} />
        </div>
      </Container>
    </div>
  )
}

export default memo(Forgot)
