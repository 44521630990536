import React, { memo, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useMutation } from 'graphql-hooks'
import { makeStyles } from '@material-ui/core/styles'
import { 
  Button,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography
} from '@material-ui/core'
import {
  Visibility,
  VisibilityOff
} from '@material-ui/icons'
import signupStyle from './style'
import { useHistory } from 'react-router-dom'
// import { Element, FormContext } from '@common/components/Elements'
import { USER_REGISTER_MUTATION } from '@gql/user'
import { SnackBar, Spinner } from '@common/components'
// import formJSON from './Forms/account.json';
import './signup.css'

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: '#fff',
    border: 'none',
    borderRadius: '0',
    outline: 'none',

    '& fieldset': {
      border: 'none'
    },

    '& input:focus, &:hover fieldset': {
      outline: 'none !important',
      border: 'none'
    },

    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: 'none',
      },
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },
  margin: {
    marginBottom: theme.spacing(2),
  }
}));

const SignUpAccount = () => {
  const styles = useStyles()
  const classes = signupStyle()
  const history = useHistory()
  const dispatch = useDispatch()
  const state = useSelector(state => state)
  const [userRegister] = useMutation(USER_REGISTER_MUTATION)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [messages, setMessages] = useState(null)
  // const [elements, setElements] = useState(null)
  // const { fields } = elements ?? {}
  const [values, setValues] = useState({
    email: '',
    password: '',
    showPassword: false
  })

  useEffect(() => {
    // setElements(formJSON[0])
    if(state.user.signupStart) {
      setValues({...values, email: state.user.signupStart})
    }

    dispatch({
      type: "SIGNUP_START",
      payload: ''
    })
  }, [dispatch, state.user.signupStart, values])

  const createAccount = () => {
    // const [email, password] = elements.fields.map(field => field.field_value)
    const { email, password }= values
    
    if(!email || !password) {
      setMessages('Please enter your email or password.')
      setOpen(true)
      return
    }

    setLoading(true)    
    dispatch({
      type: "SIGNUP_SUCCESS",
      payload: email
    })

    userRegister({
      variables: {
        email,
        password
      }
    }).then( ({data, error}) => {
      if (data?.register === null) {
        const { message } = error.graphQLErrors[0]
        setMessages(message)
        setLoading(false)
        setOpen(true)
        return
      }

      setMessages('Your account registration is almost done. An email has been sent to your email address containing an activation link. Please click on the link to activate your account. Redirecting ...')
      setOpen(true)
      setLoading(false)
      setTimeout(function() {
        history.push('/signup/categories')
      }, 5000)
    })
  }

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  // const handleInputChange = e => {
  //   const {name, value} = e.target
  //   setValues({...values, [name]: value})
  // }

  // const handleChange = (id, event) => {
  //   const newElements = { ...elements }
  //   newElements.fields.forEach(field => {
  //     const { field_type, field_id } = field;
  //     if (id === field_id) {
  //       switch (field_type) {
  //         case 'checkbox':
  //           field['field_value'] = event.target.checked;
  //           break;

  //         default:
  //           field['field_value'] = event.target.value;
  //           break;
  //       }
  //     }
  //     setElements(newElements)
  //   })
  // }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div className={classes.signUpWrapper}>
      <Container className={classes.root}>
        <Typography variant="caption">Step 1 of 3</Typography>
        <Typography variant="h5">Create an account to start</Typography>
        <div className={classes.center}>
          <div className={classes.stepContext}>
            <form noValidate autoComplete="off">
              <FormControl variant="filled" fullWidth className={styles.margin}>
                <TextField
                  variant="filled"
                  required              
                  label="Email Address"
                  name="email"
                  autoComplete="off"
                  autoFocus
                  onChange={handleChange('email')}
                  className={styles.textField}
                />
              </FormControl>
              <FormControl variant="filled" fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                  id="email"
                  name="email"
                  type={values.showPassword ? 'text' : 'password'}
                  onChange={handleChange('password')}
                  value={values.password}
                  autoComplete="off"
                  className={styles.textField}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
              </FormControl>
            </form>
            <br/>
            {loading ? <Spinner /> : <Button fullWidth variant="contained" color="primary" onClick={() => createAccount()} className={classes.button}>Continue</Button>}
          </div>
        </div>
      </Container>

      <SnackBar messages={messages} open={open} close={handleClose} />
    </div>
  )
}

export default memo(SignUpAccount)