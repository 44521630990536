import React, { memo } from 'react'
import {
	Container,
  Typography,
} from '@material-ui/core'
import { CommonStyle } from '@common/styles'
import pagesStyle from './style'

const AboutConsulusClass = () => {
	const Pages = pagesStyle()
	const Common = CommonStyle()

	return (
		<div className={Pages.root}>
			<Container className={Common.content}>
				<div className={Common.body}>
					<Typography variant="h6" gutterBottom>ABOUT CONSULUS CLASS</Typography>
					<Typography variant="subtitle2" gutterBottom>A platform for your journey to be the difference you are meant to be</Typography>
					<br />
					<Typography variant="body2" gutterBottom>Consulus Class is an online learning platform for personal and organizational development. It features unique methods, lessons and expert insights based on Consulus’ 18 years of experience in transforming organizations, leaders, and cities all over the world.</Typography>
					<br />
					<Typography variant="body2" gutterBottom>By joining the platform, learners will find when to learn what, in order to follow their life purpose. Learners can also access toolkits for opportunities to contribute back to the system, as subject matter experts, so others can benefit from their experiences.</Typography>
					<br />
					<Typography variant="body2" gutterBottom>In June 2022, Consulus introduced Consulus Class (beta) to enable greater learning on purpose-driven business, organization and personal transformation among Consulus consultants, clients, partners and its network of Shape the World Changemakers.</Typography>
					<br />
					<Typography variant="body2" gutterBottom>Beta Consulus Class is best viewed on desktop.</Typography>
				</div>
			</Container>
		</div>
	)
}

export default memo(AboutConsulusClass)
