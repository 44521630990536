import React, { memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles'
import {
  AppBar,
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Toolbar
} from '@material-ui/core'
import {
  // Notifications,
  PowerSettingsNew,
  // Search
} from '@material-ui/icons'
import { NavLink } from 'react-router-dom'
// import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  navigationMenu: {
    display: 'flex',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),

    "& li": {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),

      "& a": {
        fontSize: '1rem',
        color: '#fff',

        "&.active": {
          color: '#00916e',
        }
      }
    }
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  logo: {
    display: 'block',
    margin: '16px',
    background: 'url(/assets/logo4.png)',
    width: '188px',
    height: '30px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat'
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  headerWrap: {
    color: '#fff',
    backgroundColor: '#000'
  }
}))

const Header = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const [open, setOpen] = useState(false)

  const handleLogout = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleUserLogout = () => {
    dispatch({
      type: 'USER_LOGOUT'
    })
  }

  return (
    <AppBar  className={classes.headerWrap}>
      <Toolbar>
        <NavLink to='/browse' className={classes.logo}></NavLink>

        <ul className={classes.navigationMenu}>
          <li>
            <NavLink to="/browse" variant="subtitle1" color="textSecondary" underline="none">Home</NavLink>
          </li>
          <li>
            <NavLink to="/discover" variant="subtitle1" color="textSecondary" underline="none">Discover</NavLink>
          </li>
          {/*
          <li>
            <NavLink to="/class" variant="subtitle1" color="textSecondary" underline="none">Classes</NavLink>
          </li>
          */}
          <li>
            <NavLink to="/my-class" variant="subtitle1" color="textSecondary" underline="none">My Classes</NavLink>
          </li>
          {/*
          <li>
            <NavLink to="/webinars" variant="subtitle1" color="textSecondary" underline="none">Webinars</NavLink>
          </li>
          */}
        </ul>

        <div className={classes.grow} />

        {/*
        <IconButton color="inherit">
          <Search />
        </IconButton>
        
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <Notifications />
          </Badge>
        </IconButton>
        */}
        
        <NavLink to="/user/profile">
          <IconButton aria-label="show 17 new notifications" color="inherit">
            <Avatar
              className={classes.avatar}
              alt="Alan Takashi"
              src={user && user.user && user.user.picture && user.user.picture.url}
            />
          </IconButton>
        </NavLink>

        <IconButton aria-label="show 11 new notifications" color="inherit" onClick={handleLogout}>
          <PowerSettingsNew />
        </IconButton>
      </Toolbar>

      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Confirm logout</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to logout?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button onClick={handleUserLogout} color="primary" autoFocus>Confirm</Button>
        </DialogActions>
      </Dialog>
    </AppBar>
  )
}

export default memo(Header);