const convertMinutesToHours = (d) => {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor(d % 3600 / 60);
  var s = Math.floor(d % 3600 % 60);

  var hDisplay = h > 0 ? h + (h === 1 ? " hr, " : " hrs, ") : "";
  var mDisplay = m > 0 ? m + (m === 1 ? " min, " : " mins, ") : "";
  var sDisplay = s > 0 ? s + (s === 1 ? " sec" : " secs") : "";
  return hDisplay + mDisplay + sDisplay; 
}

const timestampToDateTime = (stamp) => {
  let date = new Date(Number(stamp))
  return date.getDate() + "/" + (date.getMonth()+1) + "/" +date.getFullYear() + " " + date.getHours() + ":"+date.getMinutes() + ":"+date.getSeconds()
}

const formatJoinDate = (stamp) => {
  let date = new Date(Number(stamp))
  return date.getDate() + "/" + (date.getMonth()+1) + "/" +date.getFullYear()
}

const truncateString = (str, n) => {
  return (str?.length > n) ? str.substr(0, n-1) + '&hellip;' : str;
}

const nextSubscrption = (stamp) => {
  let date = new Date(Number(stamp))
  let nextDate = date.getDate() + "/" + (date.getMonth()+1) + "/" + (date.getFullYear()+1)
  return nextDate
}

export {
  convertMinutesToHours,
  timestampToDateTime,
  formatJoinDate,
  truncateString,
  nextSubscrption
}
