import { makeStyles } from '@material-ui/core/styles'

const Form = makeStyles((theme) => ({
	textField: {
    border: 'none',
    borderRadius: '0',
    outline: 'none',

    '& .MuiOutlinedInput-root': {
      borderRadius: '0',
      backgroundColor: '#ffffff',
    },

    '& fieldset': {
      border: 'none'
    },

    '&:hover fieldset': {
      border: 'none'
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  }
}))

export default Form