import React, { memo, useState, useEffect } from 'react'
import { useMutation } from 'graphql-hooks'
import { NavLink, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import {
	Button,
	Container,
	TextField,
	Typography
} from "@material-ui/core"
import { VERIFY_SUBSCRIBER_TOKEN } from '@gql/user'

const useStyles = makeStyles((theme) => ({
	homeWrapper: {
		height: '100%'
	},
	root: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
    height: '100%',
    zIndex: 9
	},
	emailSignUp: {
		display: 'flex',
		margin: '32px 0',

		'& fieldset': {
			borderRadius: '0'
		},

		[theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
	},
	textField: {
		backgroundColor: '#fff',
		width: '500px',
		border: 'none',
		borderRadius: '0',
		outline: 'none',

		'& fieldset': {
			border: 'none'
		},

		'&:hover fieldset': {
			border: 'none'
		},

		[theme.breakpoints.down('sm')]: {
      width: '100%'
    },
	},
	signUp: {
		backgroundColor: '#00916e',
		width: '300px',
		border: '0',
		borderRadius: '0',
		fontSize: '24px',
		fontWeight: '400',
		color: '#fff',
		opacity: '1',

		'&:hover': {
			border: 'none',
			backgroundColor: '#00916e',
			opacity: '0.8',
		}
	},
	socialSignUp: {
		display: 'none',
    justifyContent: 'center',

		"& img": {
			margin: '0 16px',
			width: '80%'
		}
	},
	concord: {
		position: 'absolute',
    top: 0,
    left: 0,
    backgroundImage: 'url(/assets/consulus-class-bg.jpg)',
    backgroundSize: 'cover',
    width: '100%',
    height: '100%'
	},
	concordGradient: {
		position: 'absolute',
		top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    background: 'rgba(0,0,0,.5)',
    backgroundImage: 'linear-gradient(to top,rgba(0,0,0,0) 50%,rgba(0,0,0,.7) 100%),radial-gradient(50% 100%,rgba(0,0,0,0) 50%,rgba(0,0,0,.7) 100%)'
	}
}))

const Home = () => {
	const classes = useStyles()
	const history = useHistory()
	const dispatch = useDispatch()
  const [userVerifyToken] = useMutation(VERIFY_SUBSCRIBER_TOKEN)
	const { user } = useSelector(state => state.user)
	const [email, setEmail] = useState(null)

	useEffect(() => {
    if (user?.accessToken) {

      userVerifyToken({
        variables: {
          token: user?.accessToken
        }
      })

      history.push('/browse')
    }
  },[user, history])

  const handleInputChange = e => {
    setEmail(e.target.value)
  }

  const signUpStart = () => {
		dispatch({
			type: 'USER_SIGNUP_START',
			payload: email
		})
		history.push('/signup/account')
  }

  return (
    <div className={classes.homeWrapper}>
    	<div className={classes.concord}>
    		{/*<div className={classes.concordGradient}></div>*/}
    	</div>
  		<Container className={classes.root}>
	    	<Typography variant="h4" component="h5" align="center" gutterBottom>
	      	WELCOME TO CONSULUS CLASS (BETA)<br/>A platform for your journey to be the difference you are meant to be
	    	</Typography>

	    	<Typography variant="h5" component="h5" gutterBottom>Enter your email address to create your account now</Typography>

	    	<div className={classes.emailSignUp}>
		 			<TextField
		        name="email"
		        className={classes.textField}
		        placeholder="Email Address"
		        variant="outlined"
		        onChange={handleInputChange}
		      />
		      <Button variant="outlined" color="primary" className={classes.signUp} onClick={signUpStart}>SIGN UP FREE</Button>
	    	</div>
	    	
	    	{/*<Typography variant="h6" gutterBottom>or</Typography>*/}

	    	<div className={classes.socialSignUp}>
	    		{/*<FacebookLogin
	          appId="198364588738626"
	          autoLoad={false}
	          fields="name,email,picture"
	          onClick={componentClicked}
	          callback={responseFacebook} />*/}
	         	<NavLink to="/browse">
	          	<img src='/assets/facebook.png' alt="" />
	          </NavLink>
	          <NavLink to="/browse">
	    				<img src='/assets/google.png' alt="" />
	  				</NavLink>
	    	</div>
	    </Container>
    </div>
  )
}

export default memo(Home);