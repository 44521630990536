import React, { memo } from 'react'
import {
	Container,
  Typography,
} from '@material-ui/core'
import { CommonStyle } from '@common/styles'
import pagesStyle from './style'

const ToolkitForContentCreators = () => {
	const Pages = pagesStyle()
	const Common = CommonStyle()

	return (
		<div className={Pages.root}>
			<Container className={Common.content}>
				<div className={Common.body}>
					<Typography variant="h6" gutterBottom>CONSULUS CLASS STANDARDS</Typography>
					<Typography variant="subtitle2" gutterBottom>How to give feedback as learners and contribute back as content creators</Typography>
					<br />
					<Typography variant="body2" gutterBottom>Any class hosted on Consulus Class strictly has to undergo quality evaluation by a committee. A class with Consulus Class standards aims to meet the following 6 criteria:</Typography>
					<Typography variant="body2" gutterBottom>- Is it relevant?</Typography>
					<Typography variant="body2" gutterBottom>- Is it credible?</Typography>
					<Typography variant="body2" gutterBottom>- Is the message delivered clearly?</Typography>
					<Typography variant="body2" gutterBottom>- Does it include Personal Experience?</Typography>
					<Typography variant="body2" gutterBottom>- Is it engaging the audience?</Typography>
					<Typography variant="body2" gutterBottom>- Does the quiz or the home assignment adequately allow you to know if the learner actually learns?</Typography>
					<br />
					<Typography variant="body2" gutterBottom>As this is the beta Consulus Class, we welcome all feedback on how to make the system work better for you. All comments and feedback can be sent to <a mailto="info@consulus.com">info@consulus.com.</a></Typography>
					<Typography variant="body2" gutterBottom>To send an inquiry about becoming a Content Creator Partner for Consulus Class, please contact us at <a mailto="info@consulus.com">info@consulus.com.</a></Typography>
					<Typography variant="body2" gutterBottom></Typography>
					<Typography variant="body2" gutterBottom></Typography>
					<Typography variant="body2" gutterBottom></Typography>
					<Typography variant="body2" gutterBottom></Typography>
					<Typography variant="body2" gutterBottom></Typography>
				</div>
			</Container>
		</div>
	)
}

export default memo(ToolkitForContentCreators)
