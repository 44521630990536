import React, { memo, useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from 'graphql-hooks'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import {
  Button,
  Box,
  Card,
  CardContent,
  CardMedia,
  Chip,
  IconButton, 
  Tab,
  Tabs,
  Typography
} from '@material-ui/core'
import { 
  Add,
  Check,
  ChevronLeft,
  ChevronRight,
  Close,
  Info,
  // PlayArrow
} from '@material-ui/icons'
import { SnackBar } from '@common/components'
import { ADD_COURSE_MUTATION, DELETE_COURSE_MUTATION } from '@gql/course'
import './style.css'

function getPrevElement(list) {
  const sibling = list[0].previousElementSibling
  
  if (sibling instanceof HTMLElement) {
    return sibling
  }

  return sibling
}

function getNextElement(list) {
  const sibling = list[list.length - 1].nextElementSibling

  if (sibling instanceof HTMLElement) {
    return sibling
  }

  return null
}

function usePosition(ref) {
  const [prevElement, setPrevElement] = useState(null)
  const [nextElement, setNextElement] = useState(null)

  useEffect(() => {
    const element = ref.current

    const update = () => {
      const rect = element.getBoundingClientRect()
     
      const visibleElements = Array.from(element.children).filter((child) => {
        const childRect = child.getBoundingClientRect()
        // console.log('childRect', childRect)

        return childRect.left >= rect.left && childRect.right <= rect.right
      })

      if (visibleElements.length > 0) {
        setPrevElement(getPrevElement(visibleElements))
        setNextElement(getNextElement(visibleElements))
      }
    }

    update()

    element.addEventListener('scroll', update, {passive: true})

    return () => {
      element.removeEventListener('scroll', update, {passive: true})
    }
  }, [ref])

  const scrollToElement = useCallback(
    (element) => {
      const currentNode = ref.current

      if (!currentNode || !element) return

      let newScrollPosition

      newScrollPosition =
        element.offsetLeft +
        element.getBoundingClientRect().width / 2 -
        currentNode.getBoundingClientRect().width / 2

      currentNode.scroll({
        left: newScrollPosition,
        behavior: 'smooth',
      })
    },
    [ref],
  )

  const scrollRight = useCallback(() => scrollToElement(nextElement), [
    scrollToElement,
    nextElement,
  ])

  const scrollLeft = useCallback(() => scrollToElement(prevElement), [
    scrollToElement,
    prevElement,
  ])

  return {
    hasItemsOnLeft: prevElement !== null,
    hasItemsOnRight: nextElement !== null,
    scrollRight,
    scrollLeft,
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Strip = ({ data, title }) => {
  const ref = useRef()
  const [activeClass, setActiveClass] = useState(false)
  const [course, setCourse] = useState('')
  const [value, setValue] = useState(0)
  const [open, setOpen] = useState(false)
  const [activeItem, setActiveItem] = useState('')
  const [messages, setMessages] = useState('')
  const [similarCourse, setSimilarCourse] = useState(null)
  const user = useSelector(state => state.user.user)
  const courses = useSelector(state => state.courses.courses)
  const [addCourse] = useMutation(ADD_COURSE_MUTATION)
  const [deleteCourse] = useMutation(DELETE_COURSE_MUTATION)
  
  const dispatch = useDispatch()

  const {
    hasItemsOnLeft,
    hasItemsOnRight,
    scrollRight,
    scrollLeft,
  } = usePosition(ref)

  const selectClass = (list) => {
    const tags = [...list.tags.map(t => t.split(' ')).join(',').split(',')]
    const filteredCourse = courses.filter(c => c._id !== list._id)

    let similar = []
    for (let i = 0; i < tags.length; i++) {
      for (let j = 0; j < filteredCourse.length; j++) {
        for (let k = 0; k < filteredCourse[j].tags.length; k++) {
          if(filteredCourse[j].tags[k] === tags[i]) {
            similar.push(filteredCourse[j])
          }
        }
      }
    }
    setSimilarCourse(similar)
    setActiveItem('')
    setActiveItem(list._id)
    setCourse('')
    setCourse(list)
    setActiveClass(true)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleClose = () => {
    setActiveItem('')
    setActiveClass(false)
  }

  const handleSaveCourse = () => {
    setMessages(`${course.name} is added to your list`)
    setOpen(true)

    dispatch({
      type: "ADD_TO_LIST",
      payload: [...user.courses, course._id]
    })

    addCourse({
      variables: {
        email: user.email,
        courseId: course._id
      }
    }).then( ({data, error}) => {
      // console.log(data)
      setMessages(`${course.name} is added to your list`)
      setOpen(true)
    })
  }

  const handleRemoveCourse = () => {
    for( var i = 0; i < user.courses.length; i++) {
      if ( user.courses[i] === course._id) {
        user.courses.splice(i, 1); 
      }
    }

    deleteCourse({
      variables: {
        email: user.email,
        courseId: course._id
      }
    }).then( ({data, error}) => {
      // console.log(data)
      setMessages(`${course.name} is removed from your list`)
      setOpen(true)
    })

    dispatch({
      type: "REMOVE_FROM_LIST",
      payload: [...user.courses]
    })
  }

  const handleCloseSnackbar = () => {
    setOpen(false)
  };

  return (
    <div className="movie-strip">
      <Typography variant="h5" gutterBottom className="movie-strip__header">{title}</Typography>
      <div className="HorizontalCenter">
        <div role="region" aria-label="Colors carousel" data-id="1" className="movie-strip__container">
          <div ref={ref} data-id="2" className="movie-strip__container-inner">
            {data && data.map((d, i) => (
              <div key={i} className={`CarouselItem ${activeItem === d._id ? 'active': ''}`}>
                <div key={d._id} className="CarouserItem" onClick={() => selectClass(d)}>
                  <img src={`/streams/${d?._id}/${encodeURIComponent(d?.cover_photo).replace(/'/g,"%27").replace(/"/g,"%22")}`} onError={(e)=>{e.target.onerror = null; e.target.src="/assets/image_placeholder.jpg"}} alt="" />
                </div>
              </div>
            ))}
          </div>

          <div className={`selected-class ${activeClass ? 'active' : 'inactive'}`}>
            <span className="close" onClick={handleClose}><Close /></span>
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
              <Tab label="Overview" {...a11yProps(0)} />
              {/*<Tab label="Details" {...a11yProps(1)} />*/}
              <Tab label="Similar like this" {...a11yProps(1)} />
            </Tabs>
            
            <TabPanel value={value} index={0}>
              <Typography variant="h6" gutterBottom>{course.name}</Typography>
              <Typography variant="body2" component="div" dangerouslySetInnerHTML={{__html: course.detail}}></Typography>
              <br/>
              <div>
                <Button component={NavLink} to={`/class/${course._id}`} variant="contained" color="primary" className="button" startIcon={<Info />}>Start course</Button>
                &nbsp;
                {/*<Button component={NavLink} to={`/class/${course._id}/watch`} variant="contained" color="primary" className="button" startIcon={<PlayArrow />}>Play the video</Button>*/}
                &nbsp;
                {user.courses.includes(course._id) ? <IconButton aria-label="delete" size="small" className="course-checked" onClick={handleRemoveCourse}><Check /></IconButton> : <Button variant="contained" color="primary" className="button" startIcon={<Add />} onClick={handleSaveCourse}>Add to My List</Button>}
              </div>
              <br/>
              <Typography variant="h6" gutterBottom>Instructor: <b>{course?.instructor?.fullname}</b></Typography>
              <br/>
              <div>
                {course && course?.tags.map((tag, i) => {
                  return (
                    <Chip label={tag} color="primary" className="tag" key={i} />
                  )
                })}
              </div>
            </TabPanel>
            {/*
            <TabPanel value={value} index={1}>
              <dl class="inline">
                {course && course?.details.map((detail, i) => {
                  return (
                    <div key={i}>
                      <dt><strong>{detail.title}</strong></dt>
                      <dd>: {detail.value}</dd>
                    </div>
                  )
                })}
              </dl>
            </TabPanel>
            */}
            <TabPanel value={value} index={1}>
              <div className="similar">
                {similarCourse && similarCourse.sort(() => Math.random() - 0.5).slice(0, 6).map(sc => {
                  return (
                    <Card className="similar-card" variant="outlined" key={sc._id}>
                      <NavLink to={`/class/${sc?._id}`}>
                        <CardMedia className="similar-media" image={`/streams/${sc?._id}/${sc?.cover_photo}`} title="" />
                        <CardContent>
                          <Typography variant="subtitle2">{sc.name}</Typography>
                        </CardContent>
                      </NavLink>
                    </Card>
                  )
                })}
              </div>
            </TabPanel>
          </div>
        </div>

        <div hasItemsOnLeft={hasItemsOnLeft} onClick={scrollLeft} aria-label='Previous slide' className={`CarouselButton LeftCarouselButton ${hasItemsOnLeft ? 'hasItemsOnLeft': ''}`}>
          <ChevronLeft />
        </div>
        <div hasItemsOnRight={hasItemsOnRight} onClick={scrollRight} aria-label='Next slide' className={`CarouselButton RightCarouselButton ${hasItemsOnRight ? 'hasItemsOnRight': ''}`}>
          <ChevronRight />
        </div>
      </div>

      <SnackBar messages={messages} open={open} close={handleCloseSnackbar} />
    </div>
  )
}

const MovieStrip = ({ data, title }) => {
	return (
		<div>
			<Strip data={data} title={title} />
		</div>
	)
}

export default memo(MovieStrip)