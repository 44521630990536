const FETCH_ALL_WEBINARS = `query webinars($skip: Int, $limit: Int) {
  webinars(skip: $skip, limit: $limit) {
    _id
    name
    category {
      _id
      name
    }
    tags
    meeting_id
    instructor {
      title
      fullname
      portfolio
      profile_img
      total_courses
    }
    cover_photo
    enrolls
    tm_started
    tm_updated
    tm_published
    published_by
    updated_by
  }
}
`

const FETCH_SINGLE_WEBINAR = `query webinar($_id: String!) {
  webinar(_id: $_id) {
    _id
    name
    category {
      _id
      name
    }
    tags
    meeting_id
    password
    instructor {
      title
      fullname
      portfolio
      profile_img
      total_courses
    }
    cover_photo
    enrolls
    tm_started
    tm_updated
    tm_published
    published_by
    updated_by
  }
}
`

export {
  FETCH_ALL_WEBINARS,
  FETCH_SINGLE_WEBINAR
}
