import React, { memo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from 'graphql-hooks'
import { makeStyles } from '@material-ui/core/styles'
import {
  Container,
  CssBaseline,
  Typography
} from '@material-ui/core'
import { USER_RESET_PASSWORD_MUTATION } from '@gql/user'
import { ResetPassword } from '@common/components'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100% - 64px)',
    display: 'flex',
    alignItems: 'center'
  },
}))

const UserPasswordReset = () => {
  const styles = useStyles()
  const history = useHistory()
  const [userResetPassword] = useMutation(USER_RESET_PASSWORD_MUTATION)
  const [loading, setLoading] = useState(false)
  const [messages, setMessages] = useState('')
  const [reset, setReset] = useState(false)
  const [values, setValues] = useState({
    email: '',
    code: '',
    password: ''
  })

  const handleInputChange = e => {
    const {name, value} = e.target
    setValues({...values, [name]: value})
  }

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const userTriggerResetPassword = () => {
    const { email, code, password } = values
    setLoading(true)

    userResetPassword({
      variables: {
        email,
        code,
        password
      }
    }).then( ({data, error}) => {
      if (data?.resetPassword === null) {
        const { message } = error.graphQLErrors[0]
        setMessages(message)
        setLoading(false)
        return
      }

      setLoading(false)
      setReset(true)
      setTimeout(function() {
        history.push('/user/profile')
      }, 2000);
    })
  }

  return (
    <div className={styles.root}>
      <CssBaseline />
      <Container component="main" maxWidth="xs">
        <Typography component="h1" variant="h5">Reset Password</Typography>
        { reset ? 
          <Typography variant="body2" align="center">Password reset successful. Redirecting ...</Typography>
          :
          <ResetPassword
            handleChange={handleChange}
            handleInputChange={handleInputChange}
            userTriggerResetPassword={userTriggerResetPassword}
            messages={messages}
            loading={loading}
            values={values}
            showLogin={true}
            requestCode="password"
            handleClickShowPassword={handleClickShowPassword}
            handleMouseDownPassword={handleMouseDownPassword}
          />}
      </Container>
    </div>
    )
}

export default memo(UserPasswordReset)
