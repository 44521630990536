import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { NavLink } from 'react-router-dom'
import {
	Card,
	CardContent,
	Typography,
} from '@material-ui/core'

const useStyle = makeStyles((theme) => ({
	header: {
		display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: 'transparent',
    color: '#fff',

		"& img": {
			margin: '0 0 32px',
			width: '150px'
		}
	},
	flexColumn: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		padding: '0'
	},
}))

const Instructor = ({ data }) => {
	const styles = useStyle()

	return (
		<Card className={styles.header}>
			<CardContent className={styles.flexColumn}>
				<Typography variant="h5" gutterBottom>Instructor</Typography>
				<img src={`/${data?.profile_img}`} onError={(e)=>{e.target.onerror = null; e.target.src="/assets/default_avatar.png"}} alt="" />
				<Typography component={NavLink} to={`/instructor/${data._id}`} ariant="h6" gutterBottom>{data?.fullname}</Typography>
				<Typography variant="body2" gutterBottom>{data?.title}</Typography>
				{/*
				<Typography variant="caption">132,955 Students</Typography>
				<Typography variant="caption">4 classes</Typography>
				*/}
			</CardContent>
		</Card>
	)
}

export default memo(Instructor)