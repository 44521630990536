import React, { 
  memo,
  useState,
  useEffect
} from 'react'
// import { useManualQuery } from 'graphql-hooks'
import { useSelector } from 'react-redux'
import {
  Typography,
  Grid,
  Link
} from '@material-ui/core'
import {
  ContentPlaceholder,
  FeaturedLeft,
  MovieStrip,
  // SnackBar
} from '@common/components'
// import { FETCH_ALL_DISCOVERS } from '@gql/course'
import { Style } from '@common/style'
import './style.css'

const development = [
  {
    title: 'Personal Development',
    gridSize: 4,
    section: [
      {
        title: 'Finding purposes',
        lists: ["Personal core values", "Finding your personal CORE"]
      }, {
        title: 'Life management',
        lists: ["Goals setting", "Personal branding", "Personal financial management", "Emotion management", "Relationship management", "Physical health & wellness", "Career discovery"]
      }
    ]
  }, {
    title: 'Professional Development',
    gridSize: 8,
    section: [
      {
        title: 'Leadership',
        lists: ["Unity building", "Mentoring", "Coaching", "Critical thinking", "Strategic thinking", "Design thinking"]
      }, {
        title: 'Business',
        lists: ["Purpose Design", "Culture Design", "Experience Design", "Economy of Communication"]
      }
    ]
  },
];

const DiscoverList = () => {
  const classes = Style()
  const state = useSelector(state => state)
  const [loading, setLoading] = useState(false)
  const [latest, setLatest] = useState('')
  const [personal, setPersonal] = useState(false)
  const [professional, setProfessional] = useState(false)

  useEffect(() => {
    setLoading(true)
    const result = state.courses.courses.reduce((r, o) => o.tm_published > r.tm_published ? o : r)
    setLatest(result)
    setLoading(false)

    // Filter and merge the 'Personal Development' courses of it parent and child
    let personalDevelopmentId = state.courses.courses.filter(c => c.category.name === 'Personal Development').map(c => c.category._id)
    let personalDevelopment = state.courses.courses.filter(c => personalDevelopmentId.includes(c.category._id) || personalDevelopmentId.includes(c.category.parent))
    setPersonal(personalDevelopment)

    // Filter and merge the 'Professional Development' courses of it parent and child
    let professionalDevelopmentId = state.courses.courses.filter(c => c.category.name === 'Professional Development').map(c => c.category._id)
    let professionalDevelopment = state.courses.courses.filter(c => professionalDevelopmentId.includes(c.category._id) || professionalDevelopmentId.includes(c.category.parent))
    setProfessional(professionalDevelopment)
  }, [state.courses.courses])

  return (
    <div>
      <div style={{marginTop: '65px'}} />

      <div className={classes.content}>
        <FeaturedLeft data={latest} />
        {loading ? <ContentPlaceholder /> : <MovieStrip data={state?.courses.courses.filter(c => c.is_featured)} title="Featured course" />}
        {loading ? <ContentPlaceholder /> : <MovieStrip data={state.courses.courses} title="New Discoveries" />}
        {loading ? <ContentPlaceholder /> : <MovieStrip data={personal} title="Personal Development" />}
        {loading ? <ContentPlaceholder /> : <MovieStrip data={professional} title="Professional Development" />}
        
        {/*
        <Grid container spacing={4} className="development__container">
          {development.map((develop, i) => {
            return (
              <Grid item xs={12} sm={develop.gridSize} key={i}>
                <Typography variant="h6" className="development__container-title">{develop.title}</Typography>
                {develop.section.map((sec, i) => (
                  <div className="development__container-section" key={i}>
                    <Typography variant="subtitle1" className="development__container-section-title">{sec.title}</Typography>
                    <ul>
                    {sec.lists.map((list, i) => (
                      <li key={i}>
                        <Link href="#" variant="body2">
                          &gt;&gt; {list}
                        </Link>
                      </li>
                    ))}
                    </ul>
                  </div>
                ))}
              </Grid>
            )
          })}
        </Grid>
        */}

        {/*<CourseDialog selectedValue={selectedValue} open={open} onClose={handleClose} />*/}

        {/*<SnackBar messages={messages} open={open} close={handleClose} />*/}
      </div>
    </div>
  )
}

export default memo(DiscoverList)