const initialState = {
  instructors: []
}

const instructors = (state = initialState, { type, payload }) => {
  switch (type) {
  	case "FETCH_INSTRUCTORS":
      return {
        ...state,
        instructors: payload
      }

    default:
      return state
  }
}

export {
	instructors
}
