import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Typography, Grid, Card, CardContent, Divider } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import Icon from '@material-ui/core/Icon'
import { PlayArrow } from '@material-ui/icons'
import YouTube from 'react-youtube'

const useStyle = makeStyles((theme) => ({
	content: {
    padding: '32px 0',

    [theme.breakpoints.down('sm')]: {
      padding: '32px 16px',
    }
  },
  body: {
    padding: '56px 0'
  },
	section: {
		margin: '0 0 32px',

		"& ul": {
			listStyle: 'disc',

			"& li": {
				marginLeft: '16px',
				paddingLeft: '16px'
			}
		}
	},
	header: {
		display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'center',
    backgroundColor: 'transparent',
    color: '#fff',

		"& img": {
			margin: '0 0 32px',
			width: '150px'
		}
	},
	courseWrapper: {
		margin: '16px 0',
		
		"& a": {
			color: 'inherit',
			textDecoration: 'none'
		}
	},
	courseItem: {
		display: 'flex',
		margin: '0 0 16px',
    border: '1px solid #4d4d4d',

    "&:hover": {
    	border: '1px solid #fff'
    }
	},
	courseMetadata: {
		display: 'flex',
    justifyContent: 'space-between',
    padding: '16px',
    width: '100%'
	},
	video: {
    position: "relative",
    paddingTop: "25px !important",
    paddingRight: "16px !important",
    paddingBottom: "56.25% !important" /* 16:9 */,
    paddingLeft: "16px !important",
    height: '0',

    [theme.breakpoints.down('sm')]: {
      "& iframe": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%"
      }
    },
  },
	download: {
    margin: '8px 0',
    textDecoration: 'none',

    "& li": {
    	display: 'flex',
    	fontSize: '20px',

    	"& a": {
    		display: 'flex',
    		alignItems: 'center',
    		paddingLeft: '16px',
    		fontSize: '14px'
    	}
    }
	},
	flexColumn: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		padding: '0'
	}
}))

const BrowseSingle = () => {
	const classes = useStyle()

	const opts = {
    height: '340',
    width: '600',
    playerVars: { // https://developers.google.com/youtube/player_parameters      
      autoplay: 0,
      controls: 0,
      rel: 0
    },
  }

  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  return (
    <div>
    	<Container className={classes.content}>
				<Grid container spacing={10} className={classes.body}>
	        
	        <Grid item xs={12} sm={9}>
	        	<div className={classes.section}>
		      		<Typography variant="h6" gutterBottom>Introduction to DigitalCORE</Typography>
		      		<Typography variant="body2" gutterBottom>The purpose of DigitalCORE will be to collaborate with our clients to identify their digital training requirements for their staff and assist to formulate a Digital Transformation Strategy to determine how Digital Technology may create value for the business.</Typography>
						</div>

						<div className={[classes.section, classes.video].join(' ')}>
							<YouTube videoId="yu-RKkb3tdk" opts={opts} onReady={_onReady} />
						</div>

						<div className={classes.section}>
		      		<Typography variant="h6" gutterBottom>This courses include</Typography>
		      		<ul>
		      			<li><Typography variant="body1" gutterBottom>3.5 hours on-demand video</Typography></li>
		      			<li><Typography variant="body1" gutterBottom>3 downloadable resources</Typography></li>
		      			<li><Typography variant="body1" gutterBottom>Full lifetime access</Typography></li>
		      			<li><Typography variant="body1" gutterBottom>Certificate of completion</Typography></li>
		    			</ul>
	    			</div>

	    			<div className={classes.section}>
	    				<Typography variant="h6" gutterBottom>Who this course if for:</Typography>
	  					<Typography variant="body2" gutterBottom>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</Typography>
	  				</div>

	  				<div className={classes.section}>
		    			<Typography variant="h6" gutterBottom>Start your courses</Typography>
		    			<div className={classes.courseWrapper}>

		    				<NavLink to='/class/video/1111'>
			  					<div className={classes.courseItem}>
			  						{/*<img src="https://via.placeholder.com/180x100" alt="" />*/}
			  						<div className={classes.courseMetadata}>
			  							<Typography variant="subtitle1">1. Lorem ipsum dolor sit amet, consectetur adipiscing elit</Typography>
			  							{/*<Typography variant="caption">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</Typography>*/}
			  							<PlayArrow />
			  						</div>
			  					</div>
		  					</NavLink>
		  					<Divider />
		  					<NavLink to='/class/video/1111'>
			  					<div className={classes.courseItem}>
			  						{/*<img src="https://via.placeholder.com/180x100" alt="" />*/}
			  						<div className={classes.courseMetadata}>
			  							<Typography variant="subtitle1">2. Lorem ipsum dolor sit amet, consectetur adipiscing elit</Typography>
			  							{/*<Typography variant="caption">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</Typography>*/}
			  							<PlayArrow />
			  						</div>
			  					</div>
		  					</NavLink>
		  					<Divider />
		  					<NavLink to='/class/video/1111'>
			  					<div className={classes.courseItem}>
			  						{/*<img src="https://via.placeholder.com/180x100" alt="" />*/}
			  						<div className={classes.courseMetadata}>
			  							<Typography variant="subtitle1">3. Lorem ipsum dolor sit amet, consectetur adipiscing elit</Typography>
			  							{/*<Typography variant="caption">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</Typography>*/}
			  							<PlayArrow />
			  						</div>
			  					</div>
		  					</NavLink>
		  					<Divider />
		  					<NavLink to='/class/video/1111'>
			  					<div className={classes.courseItem}>
			  						{/*<img src="https://via.placeholder.com/180x100" alt="" />*/}
			  						<div className={classes.courseMetadata}>
			  							<Typography variant="subtitle1">4. Lorem ipsum dolor sit amet, consectetur adipiscing elit</Typography>
			  							{/*<Typography variant="caption">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</Typography>*/}
			  							<PlayArrow />
			  						</div>
			  					</div>
		  					</NavLink>
		  					<Divider />
		  				</div>
		  			</div>
	    		</Grid>
	    		
	    		<Grid item xs={12} sm={3}>
	    			<Card className={classes.header}>
	    				<CardContent className={classes.flexColumn}>
	    					<Typography variant="h5" gutterBottom>Instructor</Typography>
    						<img src="https://media-exp1.licdn.com/dms/image/C5603AQGBRhE7Petasw/profile-displayphoto-shrink_200_200/0/1516359251498?e=1618444800&v=beta&t=KiUgQ4jQbfF_SDEXZj1t024nZ7UH_bVWe_NjPcfz5vk" alt="" />
    						<Typography variant="h6" gutterBottom>Michael Dai</Typography>
    						<Typography variant="body2" gutterBottom>Global Head, Digital Transformation</Typography>
    						<Typography variant="caption">132,955 Students</Typography>
    						<Typography variant="caption">4 classes</Typography>
	    				</CardContent>
	    			</Card>

	    			<br/>

	    			<Typography variant="h6" gutterBottom>Download course meterials</Typography>
	    			<ul className={classes.download}>
	    				<li>&bull;<a href='http://www.africau.edu/images/default/sample.pdf' download target="_blank" rel="noopener noreferrer"><Icon>get_app</Icon> Chapter 1</a></li>
	    				<li>&bull;<a href='http://www.africau.edu/images/default/sample.pdf' download target="_blank" rel="noopener noreferrer"><Icon>get_app</Icon> Chapter 2</a></li>
	    				<li>&bull;<a href='http://www.africau.edu/images/default/sample.pdf' download target="_blank" rel="noopener noreferrer"><Icon>get_app</Icon> Chapter 3</a></li>
    				</ul>
	    		</Grid>
	    	</Grid>
    	</Container>
    </div>
  )
}

export default memo(BrowseSingle)