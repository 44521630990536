const ADD_COURSE_MUTATION = `mutation addSubscriberCourse($email: String!, $courseId: String!) {
  addSubscriberCourse(email: $email, courseId: $courseId) {
    email
    courses
  }
}
`
const DELETE_COURSE_MUTATION = `mutation deleteSubscriberCourse($email: String!, $courseId: String!) {
  deleteSubscriberCourse(email: $email, courseId: $courseId) {
    email
    courses
  }
}
`

const CREATE_CERTIFIFCATE_MUTATION = `mutation createCertificate($certificate: CertificateInput!) {
  createCertificate(certificate: $certificate) {
    subscriberId
    courseId
  }
}
`

const CREATE_PLAYRECORD_MUTATION = `mutation createPlayRecord($record: PlayRecordInput!) {
  createPlayRecord(record: $record) {
    course
    lecture
    instructor
    subscriber
    tm_play
  }
}
`

export {
  ADD_COURSE_MUTATION,
  DELETE_COURSE_MUTATION,
  CREATE_CERTIFIFCATE_MUTATION,
  CREATE_PLAYRECORD_MUTATION
}
