import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Dialog } from '@material-ui/core'

const useStyle = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paper": {
      backgroundColor: '#000',
    }    
  },
  video: {
    width: '100%',
  },
}))

const ModalTrailer = ({ url, open, close }) => {
	const classes = useStyle()
  const fullWidth = true

	return (
		<Dialog open={open} onClose={close} scroll="body" fullWidth={fullWidth} maxWidth="md" className={classes.dialog}>
      <video src={url} width="850" controls controlsList="nodownload" type="video/mp4" className={classes.video} playsInline />
    </Dialog>
	)
}

export default memo(ModalTrailer)