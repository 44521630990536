import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import teal from '@material-ui/core/colors/teal'
/**
 * Grpahql
 */
import { ClientContext } from 'graphql-hooks'
import GqlClient from "@utils/graphqlClient"

/**
 * Intl
 */
import { IntlProvider } from 'react-intl'
import translations from '@locales'
import Routes from '@routes'

const theme = createMuiTheme({
  palette: {
    primary: teal,
    secondary: {
      light: '#e91e63',
      main: '#e91e63',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffffff',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: ["Raleway", "sans-serif"].join(','),
  },
});
const gClient = new GqlClient();
gClient.init(null);

function App() {  
  const lang = 'en-US';
  return (
    <IntlProvider locale={lang} messages={translations[lang]}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <ClientContext.Provider value={gClient.client}>
          <Routes />
        </ClientContext.Provider>
      </MuiThemeProvider>
    </IntlProvider>
  );
}

export default App;
