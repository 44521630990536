const FETCH_ALL_COURSES = `query courses($limit: Int) {
  courses(limit: $limit) {
    _id
    category {
      _id
      name
      parent
      ancestors
      total_courses
    }
    tags
    name
    detail
    summary
    is_featured
    banner
    lectures {
      _id
      is_free
      name
      note
      video
      video_duration
    }
    instructor {
      _id
      title
      fullname
      portfolio
      profile_img
      total_courses
    }
    cover_photo
    trailer_video
    rating_score
    tm_updated
    tm_published
    published_by
    updated_by
  }
}
`

const FETCH_COURSE_BY_ID = `query course($_id: String!) {
  course(_id: $_id) {
    _id
    category {
      _id
      name
      parent
      ancestors
      total_courses
    }
    tags
    name
    detail
    summary
    lectures {
      _id
      is_free
      name
      note
      video
      video_duration
      tm_video_updated
      tm_updated
      tm_published
    }
    instructor {
      _id
      title
      fullname
      portfolio
      profile_img
    }
    trailer_video
    materials
    enrolls
    rating_score
    tm_updated
    tm_published
    published_by
    updated_by
  }
}
`

const FETCH_QUIZ_BY_ID = `query quiz($courseId: String!) {
  quiz(courseId: $courseId) {
    courseId
    questions {
      question
      choices
      answer
    }
  }
}
`

const FETCH_CERTIFIFCATE_BY_SUBSCRIBER = `query certificates($subscriberId: String!) {
  certificates(subscriberId: $subscriberId) {
    _id
    subscriberId
    courseId
    tm_awarded
  }
}
`

export {
  FETCH_ALL_COURSES,
  FETCH_COURSE_BY_ID,
  FETCH_QUIZ_BY_ID,
  FETCH_CERTIFIFCATE_BY_SUBSCRIBER
}
