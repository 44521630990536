import React, { memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from 'graphql-hooks'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  Chip,
  Dialog,
  Divider,
  IconButton,
  Typography
} from '@material-ui/core'
import { ArrowBack, PlayArrow, SkipNext, SkipPrevious } from '@material-ui/icons'
import { UPDATE_COURSE_PROGRESS } from '@gql/user'
import { CREATE_PLAYRECORD_MUTATION } from '@gql/course'
import { convertMinutesToHours } from '@helpers'
import { SnackBar } from '@common/components'

const useStyle = makeStyles((theme) => ({
  section: {
    margin: '0 0 32px',

    "& ul": {
      listStyle: 'disc',

      "& li": {
        marginLeft: '16px',
        paddingLeft: '16px'
      }
    }
  },
  courseWrapper: {
    margin: '16px 0',
    
    "& a": {
      color: 'inherit',
      textDecoration: 'none'
    }
  },
  courseItem: {
    display: 'flex',
    margin: '0 0 16px',
    border: '1px solid #4d4d4d',
    cursor: 'pointer',

    "&:hover": {
      border: '1px solid #fff'
    }
  },
  courseMetadata: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px',
    width: '100%'
  },
  lectureDuration: {
    minWidth: '15%',
    flexGrow: 1
  },
  lectureName: {
    minWidth: '80%',
    flexGrow: 12
  },
  lectureIcon: {
    minWidth: '5%',
    flexGrow: 1
  },
  dialog: {
    "& .MuiDialog-paper": {
      backgroundColor: '#000',
    }    
  },
  return: {
    position: 'absolute',
    top: '3%',
    left: '3%',
    zIndex: '9',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  video: {
    position: 'relative',
    width: '100% !important',
    height: '100% !important'
  }
}))

const Lectures = ({ data }) => {
  const styles = useStyle()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [snackOpen, setSnackOpen] = useState(false)
  const [lecture, setLecture] = useState(null)
  const [source, setSource] = useState(null)
  const [messages, setMessages] = useState('')
  const [url, setUrl] = useState('')
  const [label, setLabel] = useState('')
  const user = useSelector(state => state.user)
  const [updateCourseProgress] = useMutation(UPDATE_COURSE_PROGRESS)
  const [createPlayRecord] = useMutation(CREATE_PLAYRECORD_MUTATION)
  const { classId } = useParams()
  let video = ''
  let videotime = ''
  let interval = 10000

  const handleClickOpen = (val) => {
    if (user.user.plan_type ==='free' && val.is_free === false) {
      setMessages('This is premium lecture, please upgrade your account to continue.')
      setUrl('/user/upgrade/subscription')
      setLabel('Upgrade account')
      setSnackOpen(true)
      return
    }

    setLecture(val)
    setOpen(true)
    setSource(`/media/streams/${classId}/${val.video}`)

    createPlayRecord({
      variables: {
        record: {
          course: classId,
          lecture: val._id,
          subscriber: user.user.email
        }
      }
    }).then(response => {
      console.log('createPlayRecord updated')
    })
  }

  function savevideoprogress(currentTime) {
    const courseProgressToUpdate = [...user.user.course_progress, {
      courseId: classId,
      lectureId: lecture._id,
      progress: currentTime
    }]

    if (user.user.course_progress.length > 0) {
      let courseProgressIndex = user.user.course_progress.findIndex(progress => {
        return progress.lectureId === lecture._id
      })

      if (courseProgressIndex === -1) {
        updateVideoProgress(courseProgressToUpdate)
      } else {
        const newCourseProgressToUpdate = {
          ...user.user.course_progress[courseProgressIndex],
          progress: currentTime
        }

        let courseProgressUpdate = [...user.user.course_progress.slice(0, courseProgressIndex), newCourseProgressToUpdate, ...user.user.course_progress.slice(courseProgressIndex + 1)]
        updateVideoProgress(courseProgressUpdate)
      }
    } else {
      updateVideoProgress(courseProgressToUpdate)
    }

    updateCourseProgress({
      variables: {
        email: user.user.email,
        courseProgress: {
          courseId: classId,
          lectureId: lecture._id,
          progress: currentTime
        }
      }
    }).then(response => {
      console.log('Lecture progress updated')
    })
  }

  function updateVideoProgress(payload) {
    dispatch({
      type: "LECTURE_PROGRESS_UPDATE",
      payload
    })
  }

  function clearVideoInterval() {
    window.clearInterval(videotime)
  }

  const handleEntered = () => {
    video = document.getElementById(`lecture_${lecture._id}`)
    let time = user.user.course_progress.find(lec => lec.lectureId === lecture._id)
    if(time) video.currentTime = time.progress

    video.onplay = () => {
      // when user start the video,
      // save the progress of the time every interval
      videotime = setInterval(function() {
        savevideoprogress(video.currentTime)
      }, interval)
    }

    video.onpause = () => {
      // when user paused the video,
      // save the progress of the time it paused
      savevideoprogress(video.currentTime)
      clearVideoInterval()
    }

    video.onended = () => {
      // when user finsihed the video,
      // save the progress of the time it finished
      savevideoprogress(video.currentTime)
      clearVideoInterval()  
    }
  }

  const handleClose = () => {
    // when user close the video without finish it,
    // save the progress of the time it stopped
    setOpen(false)
    setLecture(null)
    clearVideoInterval()
  }

  const handleSnackClose = () => {
    setSnackOpen(false)
  }

  const playPrevious = (id) => {
    let vindex = data.findIndex(x => x._id === id);
    if(vindex === 0) {
      return
    }
    setLecture(data[vindex - 1])
    setSource(`/media/streams/${classId}/${data[vindex - 1].video}`)
  }

  const playNext = (id) => {
    let vindex = data.findIndex(x => x._id === id);
    if(data.length - 1 === vindex) {
      return
    }
    setLecture(data[vindex + 1])
    setSource(`/media/streams/${classId}/${data[vindex + 1].video}`)
  }

  return (
    <div className={styles.section}>
      <Typography variant="h6" gutterBottom>Start your learning</Typography>
      <div className={styles.courseWrapper}>
        {data.map(d => (
          <div onClick={() => handleClickOpen(d)} key={d._id}>
            <div className={styles.courseItem}>
              {/*<img src="https://via.placeholder.com/180x100" alt="" />*/}
              <div className={styles.courseMetadata}>
                <Typography variant="caption" component="span" className={styles.lectureDuration}>{convertMinutesToHours(d.video_duration)}</Typography>
                <Typography variant="subtitle1" className={styles.lectureName}>{d.name} {d.is_free ? '' : <Chip label="PREMIUM" variant="outlined" size="small" color="primary" /> }</Typography>
                {/*<Typography variant="caption">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</Typography>*/}
                <PlayArrow className={styles.lectureIcon} />
              </div>
            </div>
          </div>
        ))}
        <Divider />
      </div>

      <Dialog open={open} onClose={() => handleClose(lecture?._id)} onEntered={handleEntered} scroll="body" fullScreen className={styles.dialog}>
        <IconButton edge="start" color="inherit" onClick={() => handleClose(lecture?._id)} aria-label="close" className={styles.return}>
          <ArrowBack fontSize="large" style={{color: '#fff'}} />
          <Typography variant="h6" style={{display: 'block', paddingLeft: '16px', color: '#fff', textAlign: 'left', lineHeight: 1.5}}>{lecture?.name}</Typography>
        </IconButton>
        {lecture?._id ? <video id={`lecture_${lecture._id}`} src={source} controls controlsList="nodownload" type="video/mp4" className={styles.video} playsInline /> : ''}
        
        <IconButton edge="start" color="inherit" onClick={() => playPrevious(lecture?._id)} aria-label="close" style={{position: 'absolute', bottom: '100px', right: '70px', color: '#fff', zIndex: 999}}>
          <SkipPrevious fontSize="large" style={{color: '#fff'}} />
        </IconButton>
        <IconButton edge="start" color="inherit" onClick={() => playNext(lecture?._id)} aria-label="close" style={{position: 'absolute', bottom: '100px', right: '15px', color: '#fff', zIndex: 999}}>
          <SkipNext fontSize="large" style={{color: '#fff'}} />
        </IconButton>
      </Dialog>

      <SnackBar messages={messages} open={snackOpen} close={handleSnackClose} url={url} label={label} />
    </div>
  )
}

export default memo(Lectures)
