import React, { Fragment, memo } from 'react'
import { Link } from 'react-router-dom'
import { IconButton, Snackbar } from '@material-ui/core'
import { Close } from '@material-ui/icons'

const SnackBar = ({ open, close, messages, url, label }) => {	
	return (
		<Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={open}
      onClose={close}
      message={messages}
      action={
        <Fragment>
          {url ? <Link to={url} style={{padding: '2px 4px', fontSize: '12px', color: '#fff', border: '1px solid #fff', borderRadius: '5px', marginRight: '16px'}}>{label}</Link> : ''}
          <IconButton size="small" aria-label="close" color="inherit" onClick={close}>
            <Close fontSize="small" />
          </IconButton>
        </Fragment>
      }
    />
	)
}

export default memo(SnackBar)