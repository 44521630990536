const FETCH_INSTRUCTORS = `query instructors($skip: Int, $limit: Int) {
  instructors(skip: $skip, limit: $limit) {
    _id
    title
    fullname
    portfolio
    profile_img
    total_courses
  }
}
`

export {
  FETCH_INSTRUCTORS
}
