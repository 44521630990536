import React, { memo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from 'graphql-hooks'
import { makeStyles } from '@material-ui/core/styles'
import {
  Container,
  CssBaseline,
  Typography
} from '@material-ui/core'
import { USER_FORGOT_PASSWORD_MUTATION } from '@gql/user'
import { ForgotPassword } from '@common/components'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100% - 64px)',
    display: 'flex',
    alignItems: 'center'
  },
}))

const UserPasswordUpdate = () => {
  const styles = useStyles()
  const history = useHistory()
  const [userForgotPassword] = useMutation(USER_FORGOT_PASSWORD_MUTATION)
  const [values, setValues] = useState({ email: '' })
  const [messages, setMessages] = useState('')
  const [loading, setLoading] = useState(false)

  const handleInputChange = e => {
    const {name, value} = e.target
    setValues({...values, [name]: value})
  }

  const userTriggerForgotPassword = () => {
    setLoading(true)

    userForgotPassword({
      variables: {
        email: values.email,
      }
    }).then( ({data, error}) => {
      if (data?.forgotPassword === null) {
        const { message } = error.graphQLErrors[0]
        setMessages(message)
        setLoading(false)
        return
      }

      setLoading(false)
      history.push('/user/update/password-reset')
    })
  }

  return (
    <div className={styles.root}>
      <CssBaseline />
      <Container component="main" maxWidth="xs">
        <Typography component="h1" variant="h5">Update Password</Typography>
        <ForgotPassword handleInputChange={handleInputChange} messages={messages} loading={loading} values={values} userTriggerForgotPassword={userTriggerForgotPassword} showLogin={false} />
      </Container>
    </div>
  )
}

export default memo(UserPasswordUpdate)
