import { combineReducers } from 'redux'

/* Reducers */
import { user } from './user'
import { signup } from './signup'
import { courses } from './courses'
import { webinars } from './webinars'
import { instructors } from './instructors'

let reducers = {
  user,
  signup,
  courses,
  webinars,
  instructors
}

const appReducer = combineReducers(reducers)
const rootReducer = (state, action) => {
  // Empty the logout user data from redux
  if (action.type === 'USER_LOGOUT') {
    state = undefined
  }

  return appReducer(state, action)
}

export {
	rootReducer
}