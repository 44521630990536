import { makeStyles } from '@material-ui/core/styles'

const Style = makeStyles((theme) => ({
  content: {
    padding: '32px',

    [theme.breakpoints.down('sm')]: {
      padding: '32px 16px',
    }
  }
}))

export {
	Style
}