const data = {
  browse: {
    banner: [
      {
        title: "Purposeful Place Design",
        description: "Inspire others with your development through a transdisciplinary solution called PlaceCORE. It encompasses people development, urban planning, business, organisational and placemaking strategies. This is an innovative solution for a new township, city or a region in transition.",
        background: "https://consulus.com/wp2/wp-content/uploads/2020/02/Screenshot-2020-02-01-at-5.09.34-PM-1.png"
      }, {
        title: "PurposeCORE™",
        description: "Business Transformation for Innovation. Since 2004, the world’s first 3-in-1 business transformation method integrating business, human resource and design.",
        background: "https://consulus.com/wp2/wp-content/uploads/2017/07/download-e1512892925956.jpg"
      }
    ]
  },
  discover: {
    banner: [
      {
        title: "PurposeCORE™",
        description: "Business Transformation for Innovation. Since 2004, the world’s first 3-in-1 business transformation method integrating business, human resource and design.",
        background: "https://consulus.com/wp2/wp-content/uploads/2017/07/download-e1512892925956.jpg"
      }
    ]
  },
  classesList: {
    banner: [
      {
        title: "UNITY 4.o AUDIT™",
        description: "Review your organization's state of unity for innovation and growth",
        background: "https://consulus.com/wp2/wp-content/uploads/2017/09/Consulus_unity_4-0-audit_collaboration.jpg"
      }
    ]
  }
}

export {
  data
}