import React, {
  memo
} from 'react'
// import { NavLink } from 'react-router-dom'
import { Container, Typography, Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import signupStyle from './style'

const SignUpMain = () => {
  const history = useHistory()
	const classes = signupStyle()

  const signUpStart = () => {
    history.push('/signup/account')
  }

  return (
    <div className={classes.signUpWrapper}>
      <Container className={classes.root}>
      	<Typography variant="h5" gutterBottom>It only take few steps to start your membership</Typography>
        	
      	<div className={classes.stepContext}>
  	      <ul>
  	      	<li>We'll remind you 3 days before you are charged for your second month.</li>
  	      	<li>No commitments, cancel anytime.</li>
  	    	</ul>
        </div>

        <div className={classes.stepAction}>
          <Button variant="contained" color="primary" onClick={() => signUpStart()} className={classes.button}>Start</Button>
          {/*<NavLink to='/signup/account' className={classes.button}>Start</NavLink>*/}
        </div>
      </Container>
    </div>
  )
}

export default memo(SignUpMain)