import React, { memo, useState } from 'react'
import { useMutation } from 'graphql-hooks'
import { NavLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  Avatar,
  Button,
  Container,
  CssBaseline,
  Grid,
  TextField,
  Typography
} from '@material-ui/core'
import {
  LockOutlined
} from '@material-ui/icons'
import { USER_REGISTER_MUTATION } from '@gql/user'
import {
  // SnackBar,
  Spinner
} from '@common/components'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100% - 64px)',
    display: 'flex',
    alignItems: 'center'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textField: {
    border: 'none',
    borderRadius: '0',
    outline: 'none',

    '& fieldset': {
      border: 'none'
    },

    '&:hover fieldset': {
      border: 'none'
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },
}));

const Register = () => {
  const styles = useStyles()
  const [userRegister] = useMutation(USER_REGISTER_MUTATION)
  // const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [messages, setMessages] = useState('')
  const [values, setValues] = useState({
    email: '',
    password: ''
  })

  const handleInputChange = e => {
    const {name, value} = e.target
    setValues({...values, [name]: value})
  }

  const userSubmitRegister = () => {
    setLoading(true)
    const { email, password } = values

    userRegister({
      variables: {
        email,
        password
      }
    }).then( ({data, error}) => {
      if (data?.register === null) {
        const { message } = error.graphQLErrors[0]
        setMessages(message)
        // setOpen(true)
        setLoading(false)
        return
      }

      setLoading(false)
      setMessages('Please check your email and verify your registration')
    })
  }

  // const handleClose = (event, reason) => {
  //   setOpen(false)
  // }

  return (
    <div className={styles.root}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={styles.paper}>
          <Avatar className={styles.avatar}>
            <LockOutlined />
          </Avatar>
          <Typography component="h1" variant="h5">Register</Typography>
          <form className={styles.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              placeholder="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleInputChange}
              className={styles.textField}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              placeholder="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleInputChange}
              className={styles.textField}
              helperText={messages ? messages : ''}
            />
            {loading ? <Spinner /> : <Button type="submit" fullWidth variant="contained" color="primary" className={styles.submit} onClick={() => userSubmitRegister()}>Register</Button>}
            <Grid container>              
              <Grid item>
                <NavLink to="/login" variant="body2">
                  {"Have an account? Login"}
                </NavLink>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>

      {/*<SnackBar messages={messages} open={open} close={handleClose} />*/}
    </div>
  )
}

export default memo(Register);