import React, { memo, useState, useEffect, useCallback } from 'react'
import { 
	useManualQuery,
	// useMutation
} from 'graphql-hooks'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import {
	Avatar,
	Button,
	Card,
	CardContent,
	CardActions,
	CardHeader,
	Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Style } from '@common/style'
import { 
	FETCH_ALL_WEBINARS, 
	// USER_ENROLL_MUTATION 
} from '@gql/webinar'
import { SnackBar } from '@common/components'
import { timestampToDateTime } from '@helpers'

const useStyles = makeStyles({
  webinarsList: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  webinar: {
    width: '18%',
    margin: '12px'
  },
  ended: {
		background: '#e5e5e5',
    color: '#858585'
  }
})

const WebinarsList = () => {
	const classes = Style()
	const styles = useStyles()
	const state = useSelector(state => state)
	const dispatch = useDispatch()
	const [getAllWebinars] = useManualQuery(FETCH_ALL_WEBINARS)
	// const [userEnroll] = useMutation(USER_ENROLL_MUTATION)
	const [open, setOpen] = useState(false)
	const [messages, setMessages] = useState('')
	// const [loading, setLoading] = useState(false)

	const fetchWebinars = useCallback(() => {
    // setLoading(true)
		getAllWebinars({
			variables: {
				skip: 0, 
				limit: 10
			}
		}).then( ({data, error}) => {
			if(error) {
				setMessages('Error fetching webinars. Please try again.')
				setOpen(true)
			} else {
				dispatch({
					type: 'FETCH_ALL_WEBINARS',
					payload: data.webinars
				})
				// setLoading(false)
			}
		})
  }, [getAllWebinars, dispatch])

	useEffect(() => {
		fetchWebinars()
	}, [fetchWebinars])

	const handleClose = (event, reason) => {
		setOpen(false)
	}

	return (
		<div>
			<div style={{marginTop: '65px'}} />
			<div className={[classes.content, styles.webinarsList].join(' ')}>
				{state.webinars.webinars.map(w => {
					return (
						<Card key={w._id} className={styles.webinar}>
							<CardHeader
								avatar={<Avatar src={w.instructor.profile_img} aria-label="recipe">R</Avatar>}
								title={`${w.instructor.title} ${w.instructor.fullname} `}
							/>
							<CardContent>
								<Typography variant="h6" gutterBottom>{w.name}</Typography>
								<Typography variant="body2" gutterBottom>{timestampToDateTime(w.tm_started)}</Typography>
								<Typography variant="body2" gutterBottom>{w.enrolls.length} enrolled</Typography>
								{/*<div>{w.enrolls.includes(state.user.user.email) ? 'True': 'False'}</div>*/}
							</CardContent>
							<CardActions className={w.tm_started > new Date().getTime() ? '' : styles.ended}>
								{/*<NavLink to={`/webinar/${w?._id}`}><Button variant="contained" color="primary" onClick={() => handleEnroll(w)} disabled={w.enrolls.includes(state.user.user.email) ? true: false}>Start course</Button></NavLink>*/}
								{w.tm_started > new Date().getTime() ? <Button component={NavLink} to={`/webinar/${w?._id}`} variant="contained" color="primary" fullWidth>Start course</Button> : <Typography variant="body2">This webinar is over</Typography>}
							</CardActions>
						</Card>
					)
				})}
				<SnackBar messages={messages} open={open} close={handleClose} />
			</div>
		</div>
	)
}

export default memo(WebinarsList)
