import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Grid,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField
} from '@material-ui/core'
import {
  Visibility,
  VisibilityOff
} from '@material-ui/icons'
import { Spinner } from '@common/components'

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),

    '& .MuiButton-contained.Mui-disabled': {
      backgroundColor: '#009688'
    }
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textField: {
    backgroundColor: '#fff',
    border: 'none',
    borderRadius: '0',
    outline: 'none',

    '& fieldset': {
      border: 'none'
    },

    '& input:focus, &:hover fieldset': {
      outline: 'none !important',
      border: 'none'
    },

    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: 'none',
      },
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },
  margin: {
    marginBottom: theme.spacing(2),
  }
}))

const ResetPassword = ({ handleChange, handleInputChange, userTriggerResetPassword, messages, loading, values, showLogin, requestCode, handleClickShowPassword, handleMouseDownPassword }) => {
  const styles = useStyles()

  return (
    <form className={styles.form} noValidate>
      <FormControl variant="filled" fullWidth className={styles.margin}>
        <TextField
          variant="filled"
          required              
          label="Please enter your email address"
          name="email"
          autoComplete="off"
          autoFocus
          onChange={handleChange('email')}
          className={styles.textField}
        />
      </FormControl>
      <FormControl variant="filled" fullWidth className={styles.margin}>
        <TextField
          variant="filled"
          required              
          label="Please enter verification code"
          name="code"
          autoComplete="off"
          autoFocus
          onChange={handleChange('code')}
          className={styles.textField}
        />
      </FormControl>
      <FormControl variant="filled" fullWidth>
        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
        <OutlinedInput
          id="email"
          name="email"
          type={values.showPassword ? 'text' : 'password'}
          onChange={handleChange('password')}
          value={values.password}
          autoComplete="off"
          className={styles.textField}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          labelWidth={70}
        />
      </FormControl>
      {loading ? <Spinner /> : <Button type="submit" fullWidth variant="contained" color="primary" className={styles.submit} onClick={() => userTriggerResetPassword()} disabled={!values.email || !values.code || !values.password}>Reset</Button>}
      <Grid container>
        <Grid item>
          <Link to={requestCode} variant="body2">
            {"Didn't receive the code? Request again"}
          </Link>
        </Grid>
      </Grid>
    </form>
  )
}

export default memo(ResetPassword)
