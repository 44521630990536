import React, { memo, useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useManualQuery, useMutation } from 'graphql-hooks'
import { Container, Typography, Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { USER_UPDATE_CATEGORIES_MUTATION } from '@gql/user'
import { FETCH_CATEGORIES } from '@gql/category'
import { SnackBar, Spinner } from '@common/components'
import signupStyle from './style'
import './signup.css'

const SignUpCategories = () => {
  const history = useHistory()
  const classes = signupStyle()
  const signup = useSelector(state => state.signup)
  const [getCategories] = useManualQuery(FETCH_CATEGORIES)
  const [userUpdateCategories] = useMutation(USER_UPDATE_CATEGORIES_MUTATION)
  const [selectedCat, setSelectedCat] = useState([])
  const [sample, setSample] = useState(false)
  const [open, setOpen] = useState(false)
  const [messages, setMessages] = useState(null)
  const [loading, setLoading] = useState(false)

  const fetchCategories = useCallback(() => {
    getCategories({
      variables: {
        skip: 0,
        limit: 10
      }
    }).then( ({data, error}) => {
      let attach_children_to_item = function (item, data) {
        item.children = data.filter(x => x.parent === item._id).map(x => attach_children_to_item(x, data));
        return item;
      };
      let tree = data.categories.map(x => attach_children_to_item(x, data.categories));
      setSample(tree)
    })
  }, [getCategories])

  useEffect(() => {
    fetchCategories()
  }, [fetchCategories])

  useEffect(() => {
    if(!signup.email) {
      history.push('/signup/account')
    }
  }, [])

  const setCategories = (val) => {    
    let index = selectedCat.findIndex(c => c._id === val._id)

    if (index === -1) {
      if (selectedCat.length === 3) {
        return
      }
      setSelectedCat([...selectedCat, val])
    } else {
      setSelectedCat([...selectedCat.slice(0, index), ...selectedCat.slice(index + 1)])
    }
  }

  const proceedToPlan = () => {
  	if (selectedCat.length !== 3) {
  		setMessages('Please select 3 categories')
  		setOpen(true)
  		return
  	}

    setLoading(true)
    let result = selectedCat.map(a => a.name)
    userUpdateCategories({
      variables: {
        email: signup.email,
        profile: {
          favourite_categories: result
        }
      }
    }).then( ({data, error}) => {
      if (data?.updateSubscriber === null) {
        const { message } = error.graphQLErrors[0]
        setMessages(message)
        setLoading(false)        
        return
      }
      setLoading(false)
      history.push('/signup/plan')
    })
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div className={classes.signUpWrapper}>
      <Container className={classes.root}>
        <Typography variant="caption">Step 2 of 3</Typography>
      	<Typography variant="h5">Choose your favourite categories</Typography>
        <Typography variant="body2" gutterBottom>Choose any 3</Typography>
        <div className={classes.stepContext}>
          <div className={classes.categories}>
            {
              // eslint-disable-next-line array-callback-return
              sample && sample.map(s => {
                if(s.children.length > 0) {
                  return (
                    <div key={s._id}>
                      <p>{s.name}</p>
                      {s.children.map(c => {
                        return (
                          <p className={`category ${selectedCat.map(ss => (ss._id === c._id) ? 'active' : '' ).join('')}`} key={c._id} onClick={() => setCategories(c)}>{c.name}</p>
                        )
                      })}
                    </div>
                  )
                }
              })
            }
          </div>
        </div>

        {/*
        <div className={classes.stepContext}>
          <div className={classes.categories}>
            {categories.map(cat => {
              return (
                <div className={`category ${selectedCat.map(s => (s.id === cat.id) ? 'active' : '' ).join('')}`} key={cat.id} onClick={() => setCategories(cat)}>{cat.name}</div>
              )
            })}
          </div>
        </div>
        */}

        <div className={classes.stepAction}>
          <div className={classes.stepTerms}>
            <Typography variant="body2">See <span>Terms of Use</span> for more details</Typography>
          </div>
          {loading ? <Spinner /> : <Button variant="contained" color="primary" onClick={() => proceedToPlan()} className={classes.button}>Continue</Button>}
          {/*<NavLink to='/signup/plan' className={classes.button}>Continue</NavLink>*/}
        </div>
      </Container>
      <SnackBar messages={messages} open={open} close={handleClose} />
    </div>
  )
}

export default memo(SignUpCategories)