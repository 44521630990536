import React, { memo } from 'react'
import { NavLink } from 'react-router-dom'
import { createStyles, makeStyles, Container, Grid, Typography } from '@material-ui/core'
// import { Facebook, LinkedIn, YouTube, Mail } from '@material-ui/icons'
import {FormattedMessage} from 'react-intl'

const useStyles =  makeStyles(theme =>
  createStyles({
    footerMenu: {
      backgroundColor: '#000',
      color: '#fff',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),

      "& ul li": {
        lineHeight: '2em',

        '& a.active': {
          color: '#fff'
        }
      }
    },
    copyright: {
      backgroundColor: '#222',
      fontSize: 11,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    footer: {
      marginTop: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },

      "& a" : {
        color: '#808080'
      }
    },
  })
)

const footers = [
  {
    title: 'About',
    description: [
      {
        name: 'About Consulus Class',
        url: '/about-consulus-class'
      }, {
        name: 'Membership plan',
        url: '/membership-plan'
      }
    ],
  },
  {
    title: 'Resources',
    description: [
      {
        name: 'Toolkit for Content creators',
        url: '/toolkit-for-content-creators'
      }
    ],
  },
  {
    title: 'Legal',
    description: [
      {
        name: 'Consulus IPs & Copyright',
        url: '/consulus-ips-copyrights'
      }, {
        name: 'Privacy Policy for Consulus Class',
        url: '/privacy-policy-consulus-class'
      }
    ],
  },
];

const Footer = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={['footerMenu', classes.footerMenu].join(' ')}>
        <Container component="footer" className={classes.footer}>
          <Grid container spacing={4} justify="space-evenly">
            {footers.map((footer) => (
              <Grid item xs={12} sm={3} key={footer.title}>
                <Typography variant="h6" gutterBottom>
                  {footer.title}
                </Typography>
                <ul>
                  {footer.description.map((item, index) => (
                    <li key={index}>
                      <NavLink to={item.url} variant="subtitle1">
                        {item.name}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </Grid>
            ))}
          </Grid>
          {/*
          <Grid container>
            <Facebook />
            <LinkedIn />
            <YouTube />
            <Mail />
          </Grid>
          */}
        </Container>
      </div>
      <div className={['copyright', classes.copyright].join(' ')}>
        <Container>
          &copy; <FormattedMessage id={'App.Consulus'} defaultMessage={'Consulus'} />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default memo(Footer);