import React, { memo } from 'react'
import {
	Container,
  Typography,
} from '@material-ui/core'
import { CommonStyle } from '@common/styles'
import pagesStyle from './style'

const PrivacyPolicyConsulusClass = () => {
	const Pages = pagesStyle()
	const Common = CommonStyle()

	return (
		<div className={Pages.root}>
			<Container className={Common.content}>
				<div className={Common.body}>
					<Typography variant="h6" gutterBottom>PRIVACY POLICY FOR CONSULUS CLASS</Typography>
					<br/>
					<br />
					<Typography variant="h5" gutterBottom>Introduction</Typography>
					<br />
					<Typography variant="body2" gutterBottom>At Consulus, we respect our customers' privacy and take the protection of their personal data seriously.</Typography>
					<br />
					<Typography variant="body2" gutterBottom>This Privacy Policy sets out the basis on which We may collect, use, or otherwise process your personal data in the course of providing the services offered on the website www.consulusclass.com (the "Platform"), such as selling and subscribing to courses, creating your profile on Consulus Class, communication, etc. (the "Services"), may collect, use, disclose or otherwise process. We also explain our policy regarding data we may collect when you visit the Platform.</Typography>
					<br />
					<Typography variant="body2" gutterBottom>Please read the following carefully to understand how we handle your personal information and how we treat it. By using the Services, you agree to the terms of this Privacy Policy. You should not use the Services if you do not agree with this Privacy Policy or any other agreement that governs your use of the Services.</Typography>
					<br />
					<Typography variant="body2" gutterBottom>Please note that applicable data protection laws provide that:</Typography>
					<Typography variant="body2" gutterBottom>- If you live in a country that is a member of the European Economic Area, the way we process your data, and the current privacy policy are governed by the General Data Protection Regulation or Regulation [EU] 2016/679 ("GDPR").</Typography>
					<Typography variant="body2" gutterBottom>- If you live in a country outside the European Economic Area, the collection of your personal information is subject to Singapore data protection laws.</Typography>
					<br />
					<Typography variant="body2" gutterBottom>The data controller for personal data is Consulus Pte. Ltd. located at Clarke Quay Central, 8 Eu Tong Seng Street, #25-87, Singapore 059818 (CONSULUS) ("We" or "Data Controller"). To contact the Data Controller's representative, please send an email to: info@consulus.com.</Typography>
					<br />
					<Typography variant="body2" gutterBottom>If the information specified as required is not provided, we may not be able to process your user registration or use of certain services available on the Platform.</Typography>
					<br />
					<Typography variant="body2" gutterBottom>To register and navigate the Platform, you must be over 16 years of age. We may use your personal information to verify your age and ensure enforcement of these restrictions.</Typography>
					<br />
					<br />
					<Typography variant="h5" gutterBottom>Legal basis for the processing of personal data</Typography>
					<br />
					<Typography variant="body2" gutterBottom>We will only process your personal data if we believe we have a lawful basis for doing so. The basis for processing varies from activity to activity. Our legal basis for processing personal data is as follows:</Typography>
					<Typography variant="body2" gutterBottom>- Consent: You have clearly consented to the processing of your personal data.</Typography>
					<Typography variant="body2" gutterBottom>- Contract: Processing is necessary for the performance of a contract.</Typography>
					<Typography variant="body2" gutterBottom>- Legal obligation: The processing is necessary to comply with the law.</Typography>
					<Typography variant="body2" gutterBottom>- Vital interests: Processing is necessary to protect your vital interests, including the protection of your rights and freedoms.</Typography>
					<Typography variant="body2" gutterBottom>- Public Interest: Processing is carried out within the scope of our official powers and in the public interest.</Typography>
					<Typography variant="body2" gutterBottom>- Legitimate interests: Processing is necessary to protect our legitimate interests or the legitimate interests of a third party, unless vital interests, including rights and freedoms, override the processing.</Typography>
					<br />
					<br />
					<Typography variant="h5" gutterBottom>Collection of personal data</Typography>
					<br />
					<Typography variant="body2" gutterBottom>We collect personal information to fulfill our role as an educational and professional organization. Because this role involves many different aspects, the information we request and collect may vary when users register for our services.</Typography>
					<br />
					<Typography variant="body2" gutterBottom>To create your profile on the Platform, you may be asked to provide additional personal information, such as your country or city, a photo, your first and last name, education, work experience, and any other additional information you wish to include in your profile. The profile information will help you make the best use of our services. However, if you do not want this information to be processed, published, and thus shared with other users, you can leave the profile information fields blank or delete the information you have already provided.</Typography>
					<br />
					<Typography variant="body2" gutterBottom>To purchase or subscribe to our courses, or if we request payment, we will redirect you to the Stripe payment platform via their plugins. If you request an invoice, we will ask you to provide us with your billing information if necessary.</Typography>
					<br />
					<Typography variant="body2" gutterBottom>When you visit or use our Services, we record information such as the frequency of use, the date and time you access the Services, when you view or click on a particular piece of content, as well as your course preferences, projects you share, or content you post. We use information when you log in, when you read our emails, and Internet Protocol ("IP") addresses to identify you and to record how you use the Platform. We may also collect other non-personal information such as browser type, operating system, and website usage metrics.</Typography>
					<br />
					<Typography variant="body2" gutterBottom>Our website may also use cookies to collect information about our users' browsing habits.</Typography>
					<br />
					<Typography variant="body2" gutterBottom>We do not make decisions based on profiles beyond personalization of advertising and legitimate prevention of Internet fraud.</Typography>
					<br />
					<br />
					<Typography variant="h5" gutterBottom>Use of personal data</Typography>
					<br />
					<Typography variant="body2" gutterBottom>We use the personal information we collect to provide our services, including creating personalized learning experiences, providing customer support, processing payments, communicating with you, and sending marketing materials.</Typography>
					<br />
					<Typography variant="body2" gutterBottom>We may also use the data to analyze user behavior and improve our services.</Typography>
					<br />
					<br />
					<Typography variant="h5" gutterBottom>Purpose of processing</Typography>
					<Typography variant="body2" gutterBottom>CONSULUS, as the data controller, will process your personal data for the following purposes:</Typography>
					<Typography variant="body2" gutterBottom>- Manage your registration as a user of the Platform to identify you and give you access to the services available to registered users of the Platform.</Typography>
					<Typography variant="body2" gutterBottom>- The development, compliance, and execution of the contract for the provision of services, such as the sale or subscription to Consulus Class courses or any other contract we have entered through the Platform.</Typography>
					<Typography variant="body2" gutterBottom>- Notify you by email of updates or information related to existing and new features, products, or services, including security updates to the Platform, as necessary or appropriate.</Typography>
					<Typography variant="body2" gutterBottom>- Responding to your inquiries through the customer service channels available on our platform.</Typography>
					<br />
					<Typography variant="body2" gutterBottom>We also use your information to create aggregate, non-identifying data. For example, we compile statistics about our users, their work experiences or areas of expertise, the number of impressions or clicks in a particular course or project, or visitor demographics.</Typography>
					<br />
					<br />
					<Typography variant="h5" gutterBottom>Options</Typography>
					<br />
					<Typography variant="body2" gutterBottom>The provision of the CONSULUS Service may involve, with your express consent, the use of your personal data to provide you with various types of personalized advertising related to our products and services, whether through emails or other electronic communications, from us or from third party collaborators. With the goal of improving our services, please be advised that CONSULUS may use personal information about your purchases, interests, and preferences for the purposes of analysis, creating user profiles, conducting quality surveys and marketing studies, and generally improving interactions with our customers.</Typography>
					<br />
					<Typography variant="body2" gutterBottom>If you do not want to receive certain types of email messages, simply access the Notifications section under Edit Profile to manage your settings.</Typography>
					<br />
					<Typography variant="body2" gutterBottom>Similarly, you can unsubscribe from your notifications by following the instructions in each notification you receive.</Typography>
					<br />
					<br />
					<Typography variant="h5" gutterBottom>Disclosure of personal data</Typography>
					<br />
					<Typography variant="body2" gutterBottom>To fulfill the purposes stated in this Privacy Policy, it may be necessary to report or share your personal information with third parties to provide our services and for other legitimate business purposes:</Typography>
					<Typography variant="body2" gutterBottom>- Business Transfers: In connection with a reorganization, restructuring, merger or sale or other transfer of assets, including personal, provided that the receiving party agrees to process your personal information in accordance with this Privacy Policy.</Typography>
					<Typography variant="body2" gutterBottom>- Service Provider: To provide services on our behalf or to help us provide our services. This means, for example, that we may engage service providers for communications, infrastructure and IT services, others to provide marketing and advertising services, and still others to customize and improve our services, process credit card transactions or other forms of payment. We may engage providers of customer service, collection, data analysis and improvement (e.g., data about user interaction with our Services), and others to conduct and administer satisfaction surveys. These partners and vendors may be located in Singapore or in other countries or territories outside the European Economic Area.</Typography>
					<br />
					<Typography variant="body2" gutterBottom>By accepting this Privacy Policy, you expressly authorize us to process and share your personal data with the aforementioned parties and partners and/or to share your personal data with the service providers as processors located in Singapore or other countries and territories outside the European Economic Area for the purposes described herein in order to provide you with a complete service. When sharing your personal data or exchanging data with countries outside the European Economic Area and other territories with data protection laws, we will ensure that the information is transferred in accordance with this Privacy Policy and in compliance with applicable data protection laws.</Typography>
					<br />
					<Typography variant="body2" gutterBottom>For legal reasons, we may also disclose personal data to law enforcement authorities or other government agencies.</Typography>
					<br />
					<Typography variant="body2" gutterBottom>We will never sell or rent our users' personal information to third parties.</Typography>
					<br />
					<br />
					<Typography variant="h5" gutterBottom>Data retention</Typography>
					<br />
					<Typography variant="body2" gutterBottom>We retain personal data only for as long as is necessary to provide our services and fulfill the purposes set forth in this Privacy Policy, for as long as we have a valid contractual and/or non-contractual relationship, and thereafter for the period of time resulting from the obligations arising from the treatment of the data or as determined by law.</Typography>
					<br />
					<br />
					<Typography variant="h5" gutterBottom>Cookies</Typography>
					<br />
					<Typography variant="body2" gutterBottom>During your use of the Platform, we may send and request data, called "cookies," to and from your computer to provide you with a more convenient browsing experience when you revisit our Platform. A cookie is a type of data that is sent by the Platform to your Internet browser software and then stored on your computer. The storage of cookies has been shown to have no adverse effect on the functioning of your computer. You must not modify the cookies sent by the Platform to your computer and must ensure that your computer returns correct and accurate cookies in response to an appropriate request from the Platform.</Typography>
					<br />
					<br />
					<Typography variant="h5" gutterBottom>Security</Typography>
					<br />
					<Typography variant="body2" gutterBottom>We use appropriate technical and organizational means and measures to protect the personal data we collect on the Platform.</Typography>
					<br />
					<br />
					<Typography variant="h5" gutterBottom>Data protection rights</Typography>
					<br />
					<Typography variant="body2" gutterBottom>As data controller, we are committed to maintaining the confidentiality of your personal data and ensuring the full exercise of these rights. You always have the following rights:</Typography>
					<Typography variant="body2" gutterBottom>- Right of access. You have the right to ascertain whether we are processing your personal data and, if we are, you have the right to obtain a copy of that data and information about the processing.</Typography>
					<Typography variant="body2" gutterBottom>- Right to Amend. You have the right to correct errors, change incomplete or incorrect data and ensure the authenticity of the information processed.</Typography>
					<Typography variant="body2" gutterBottom>- Right to erasure. You have the right to request the immediate deletion of your data if they have been processed unlawfully or if the purpose for which they were processed or collected has ceased to exist.</Typography>
					<Typography variant="body2" gutterBottom>- Right to restriction of processing. You have the right to request the suspension of the processing of your data if it is unlawful or if the accuracy of the data is contested.</Typography>
					<Typography variant="body2" gutterBottom>- Right to object. You have the right to object to the processing of your data if it has been used for direct marketing purposes or if the processing must be stopped due to a personal situation, unless there is a legitimate interest or the processing is necessary for the assertion or defense of claims.</Typography>
					<Typography variant="body2" gutterBottom>- Right not to be subject to personalized decisions. You have the right not to be subject to any decision based solely on the automated processing of your data - including profiling - which produces binding legal effects, or which concerns you, unless it is necessary for the performance of a contract, permitted by law or expressly authorized by you.</Typography>
					<Typography variant="body2" gutterBottom>- Right to revoke consent. You have the right to revoke your consent at any time. The revocation of consent does not affect the lawfulness of the processing carried out on the basis of the consent until the revocation.</Typography>
					<br />
					<Typography variant="body2" gutterBottom>To exercise these rights, please send an email to: info@consulus.com indicating (i) your identity by providing your full name and email address used when registering or purchasing from Consulus Class, as well as identification to establish your identity, and (ii) the right or rights you wish to exercise.</Typography>
					<br />
					<Typography variant="body2" gutterBottom>You may also contact the body responsible for data protection in your country to lodge a complaint or request protection of your rights if deemed necessary.</Typography>
					<br />
					<Typography variant="body2" gutterBottom>The exercise of these rights is free of charge, unless the requests are intentionally unfounded or excessive, in which case the data subject must bear the costs of the processing.</Typography>
					<br />
					<br />
					<Typography variant="h5" gutterBottom>Changes</Typography>
					<br />
					<Typography variant="body2" gutterBottom>We review this Privacy Policy periodically and will post any updates required by law on our website.</Typography>
				</div>
			</Container>
		</div>
	)
}

export default memo(PrivacyPolicyConsulusClass)
