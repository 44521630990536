import React, {
  memo
} from 'react';

const Error404 = () => 
{
  return (
    <div>404 error</div>
  )
}

export default memo(Error404);