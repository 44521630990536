import React, { memo, useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Container, Typography } from '@material-ui/core'
import * as Survey from "survey-react"
import { useParams, useHistory } from 'react-router-dom'
import { useManualQuery, useMutation } from 'graphql-hooks'
import { FETCH_QUIZ_BY_ID, CREATE_CERTIFIFCATE_MUTATION } from '@gql/course'
import { SnackBar } from '@common/components'

import "survey-react/survey.css"
import "./custom-survey.css"

const useStyle = makeStyles((theme) => ({
	content: {
    padding: '32px 0'
  },
  body: {
    padding: '56px 0'
  },
  chartWrap: {
    position: 'relative'
  },
  result: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  score: {
    position: 'absolute',
    top: '33%',
    right: 0,
    left: 0,    
    margin: '0 auto',
    fontSize: '46px',
    fontWeight: 300,
    textAlign: 'center'
  },
  svgPie: {
    width: '130px',
    height: '130px',
    transform: 'rotate(-90deg)',
    borderRadius: '50%',
  },
  donutSvg: {
    width: '200px',
    transform: 'rotate(-90deg)',

    "& span": {
      position: 'absolute',
      top: '33%',
      fontSize: '48px'    
    }
  },
  donutSvgBase: {
    stroke: 'rgb(17 95 85 / 90%)',
    strokeWidth: 32
  },
  donutSvgValue: {
    stroke: 'rgb(24 200 179 / 90%)',
    strokeWidth: 32
  },
  donutSvgHole: {
    fill: '#141414'
  },
  claim: {
    margin: '16px 0'
  }
}))

let surveyTemplate = {
  showProgressBar: "bottom",
  showPrevButton: true,
  firstPageIsStarted: true,
  startSurveyText: "Start Quiz",
  pages: [
    {
      questions: [
        {
          type: "html",
          html: "You are about to start quiz. <br/>You have 10 seconds for every page and 25 seconds for the whole survey of 10 questions.<br/>If your pass you will receive a certificate at the end.<br/>Please click on <b>'Start Quiz'</b> button when you are ready.<br/>Good luck!"
        }
      ]
    }
  ],
  completedHtml: "<h4></h4>"
}

const ClassQuiz = () => {
	const styles = useStyle()
  const { classId } = useParams()
  const history = useHistory()
  const user = useSelector(state => state.user)
  const [complete, setComplete] = useState(false)
  const [quiz, setQuiz] = useState(null)
  const [answer, setAnswer] = useState(null)
  const [questions, setQuestions] = useState(null)
  const [score, setScore] = useState(null)
  const [empty, setEmpty] = useState(false)
  const [messages, setMessages] = useState('')
  const [getQuizById] = useManualQuery(FETCH_QUIZ_BY_ID)
  const [createCertificate] = useMutation(CREATE_CERTIFIFCATE_MUTATION)
  const [open, setOpen] = useState(false)
  
  const fetchQuizById = useCallback(() => {
    getQuizById({
      variables: {
        courseId: classId
      }
    }).then( ({data, error}) => {
      if(error) {
        console.log(error)
      } else {
        if(data.quiz === null) {
          setEmpty(true)
          return
        }

        if(data.quiz) {
          let transformed = {
            ...surveyTemplate,
            title: "",
            pages: [
              ...surveyTemplate.pages,
              ...data.quiz.questions.map(({ question, choices, answer }) => ({
                questions: [
                  {
                    type: "radiogroup",
                    choicesOrder: "random",
                    title: question,
                    choices,
                    correctAnswer: answer,
                  },
                ],
              }))
            ]
          };
          const model = new Survey.Model(transformed)
          setQuiz(model)
        }
      }
    })
  }, [getQuizById, classId])

  useEffect(() => {
    fetchQuizById()
  }, [fetchQuizById])

  const renderResult = (answer, questions, score) => {
    const handleQuizReset = () => {
      window.location.reload()
    }

    const handleClaimCertificate = () => {
      createCertificate({
        variables: {
          certificate: {
            subscriberId: user.user.email,
            courseId: classId
          }
        }
      }).then( ({data, error}) => {
        if (data?.createCertificate === null) {
          const { message } = error.graphQLErrors[0]
          setMessages(message)
          return
        }

        window.location.replace(`/class/${classId}/certificate`)
      })
    }

    const handleClose = () => {
      setOpen(false)
    }

    return (
      <div className={styles.result}>
        <div className={styles.chartWrap}>
          <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" className={styles.donutSvg}>
            <circle className={styles.donutSvgBase} cx="50%" cy="50%" r="25%"></circle>
            <circle className={styles.donutSvgValue} cx="50%" cy="50%" r="25%" strokeDasharray={`${score}, 100`}></circle>
            <circle className={styles.donutSvgHole} cx="50%" cy="50%" r="40%"></circle>
          </svg>
          <span className={styles.score}>{Math.trunc(score)}%</span>
        </div>
        <Typography>{score === 100 ? 'Congratulations you have passed the quiz. You can now claim your certificate by clicking the button below' : 'Unfortunately you need 100% correct to pass this quiz'}</Typography>
        {score === 100 ? <Button variant="contained" color="primary" className={styles.claim} onClick={handleClaimCertificate}>Claim your certificate</Button> : <Button variant="contained" color="primary" className={styles.claim} onClick={handleQuizReset}>Restart the Quiz</Button>}

        <SnackBar messages={messages} open={open} close={handleClose} />
      </div>
    )
  }

	const onComplete = (survey, options) => {
    const result = survey.data;
    result["correct_answers"] = survey.getCorrectedAnswerCount();
    result["no_of_questions"] = survey.getQuizQuestionCount();
    const quizScore = (result["correct_answers"] / result["no_of_questions"]) * 100
    setAnswer(result["correct_answers"])
    setQuestions(result["no_of_questions"])
    setScore(quizScore)

    // if (score * 3.6 != 0) {
    //   setChart(score * 3.6)
    // }
    
    setComplete(true)
 	}

  return (
    <div>
      <Container className={styles.content}>
        <div className={styles.body}>
			    {complete && renderResult(answer, questions, score)}
          {quiz && <Survey.Survey model={quiz} onComplete={onComplete} />}
          {empty && <div><Typography>Sorry, there is no quiz ready for this course yet.</Typography><Button variant="contained" color="primary" onClick={() => history.goBack()}>Go back</Button></div>}
        </div>        
      </Container>
    </div>
  )
}

export default memo(ClassQuiz)