const initialState = {
  signupStart: "",
  user: {},
  courses: []
}

const user = (state = initialState, {type, payload}) => {
  switch (type) {
    case 'USER_LOGOUT':
      return { ...state, ...payload }

    case 'USER_LOGIN_SUCCESS':
      return {
        ...state,
        user: payload
      }

    case 'USER_SIGNUP_START':
      return {
        ...state,
        signupStart: payload
      }

    case "LECTURE_PROGRESS_UPDATE":
      return {
        ...state,
        user: {
          ...state.user,
          course_progress: payload
        }
      }

    case 'USER_FBLOGIN_SUCCESS':
      return {
        ...state,
        authenticating: false,
        user: {
          "name": payload.name,
          "email": payload.email,
          picture: {
            "height": payload.picture.data.height,
            "width": payload.picture.data.width,
            "url": payload.picture.data.url
          }
        },
        userid: payload.id,
        authenticated: true
      }

    case 'ADD_TO_LIST':
      return {
        ...state,
        user: {
          ...state.user,
          courses: payload
        }
      }

    case 'REMOVE_FROM_LIST':
      return {
        ...state,
        user: {
          ...state.user,
          courses: payload
        }
      }

    case 'USER_ACCOUNT_UPGRADE_SUCCESS':
      return {
        ...state,
        user: payload
      }

    case 'USER_CANCEL_SUBSCRIPTION':
      return {
        ...state,
        user: payload
      }      

    default:
      return state
  }
}

export {
  user
}