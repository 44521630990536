const initialState = {
  email: ''
}

const signup = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SIGNUP_START':
      return { ...state, email: payload}

    case 'SIGNUP_SUCCESS':
      return { ...state, email: payload}

    default:
      return state
  }
}

export {
	signup
}