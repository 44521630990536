import React, { memo, useState, useEffect, useCallback } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
import { useParams, NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useManualQuery } from 'graphql-hooks'
import { makeStyles } from '@material-ui/core/styles'
import {
  Chip,
  Container,
  Grid,
  Typography,
} from '@material-ui/core'
// import { ArrowBack } from '@material-ui/icons' 
import { FETCH_COURSE_BY_ID, FETCH_QUIZ_BY_ID, FETCH_CERTIFIFCATE_BY_SUBSCRIBER } from '@gql/course'
import Lectures from '@classes/components/Lectures'
import Instructor from '@classes/components/Instructor'
import Materials from '@classes/components/Materials'
// import YouTube from 'react-youtube'
import { SnackBar } from '@common/components'

const useStyle = makeStyles((theme) => ({
  content: {
    padding: '32px 0',

    [theme.breakpoints.down('md')]: {
      padding: '32px 44px',
    },

    [theme.breakpoints.down('sm')]: {
      padding: '32px 16px',
    }
  },
  body: {
    padding: '56px 0'
  },
  section: {
    margin: '0 0 32px',

    "& ul": {
      listStyle: 'disc',

      "& li": {
        marginLeft: '16px',
        paddingLeft: '16px'
      }
    }
  },
  video: {
    width: '100% !important',
    height: '100% !important'
  },
  quiz: {
    padding: '16px',
    border: '1px solid #4d4d4d',
  }
}))

const ClassSingle = () => {
  const styles = useStyle()
  const user = useSelector(state => state.user.user)
  // const coursesState = useSelector(state => state.courses)
  // const [open, setOpen] = useState(false)
  const [getCourseById] = useManualQuery(FETCH_COURSE_BY_ID)
  const [getQuizById] = useManualQuery(FETCH_QUIZ_BY_ID)
  const [getCertificateBySubscriber] = useManualQuery(FETCH_CERTIFIFCATE_BY_SUBSCRIBER)
  const [course, setCourse] = useState(null)
  const [emptyQuiz, setEmptyQuiz] = useState(false)
  // const [video, setVideo] = useState(null)
  // const dispatch = useDispatch()
  const { classId } = useParams()
  // const fullWidth = true
  const [messages, setMessages] = useState('')
  const [url, setUrl] = useState('')
  const [label, setLabel] = useState('')
  const [snackOpen, setSnackOpen] = useState(false)
  const [certificate, setCertificate] = useState(null)

  const fetchCourseById = useCallback(() => {
    getCourseById({
      variables: {
        _id: classId
      }
    }).then( ({data, error}) => {
      if(error) {
        console.log(error)
      } else {
        setCourse(data.course)
      }
    })
  }, [getCourseById, classId])

  const fetchQuizById = useCallback(() => {
    getQuizById({
      variables: {
        courseId: classId
      }
    }).then( ({data, error}) => {
      if(data.quiz === null) {
        setEmptyQuiz(true)
        return
      }
    })
  }, [getQuizById, classId])

  const fetchCertificateBySubscriber = useCallback(() => {
    getCertificateBySubscriber({
      variables: {
        subscriberId: user.email
      }
    }).then( ({data, error}) => {
      if (data?.getCertificateBySubscriber === null) {
        const { message } = error.graphQLErrors[0]
        setMessages(message)
        return
      }
      setCertificate(data?.certificates.filter(c => c.courseId === classId)[0])
    })
  }, [getCertificateBySubscriber, user.email])

  useEffect(() => {
    fetchCourseById()
  }, [fetchCourseById])

  useEffect(() => {
    fetchQuizById()
  }, [fetchQuizById])

  useEffect(() => {
    fetchCertificateBySubscriber()
  }, [fetchCertificateBySubscriber])

  const handleQuizClick = (e) => {
    if (user.plan_type ==='free') {
      e.preventDefault()
      setMessages('This is premium lecture, please upgrade your account to continue.')
      setUrl('/user/upgrade/subscription')
      setLabel('Upgrade account')
      setSnackOpen(true)
      return
    }
  }

  const handleSnackClose = () => {
    setSnackOpen(false)
  }

  return (
    <div>
      <Container className={styles.content}>
        <Grid container spacing={4} className={styles.body}>
          
          <Grid item xs={12} sm={8} md={9}>
            <div className={styles.section}>
              <Typography variant="h4" gutterBottom>{course?.name}</Typography>
              <Typography variant="body2" gutterBottom dangerouslySetInnerHTML={{__html: course?.detail}}></Typography>
            </div>

            <div className={styles.section}>
              <video id={`course_${classId}`} src={`/media/${course?.trailer_video}`} type="video/mp4" controls controlsList="nodownload" className={styles.video} playsInline />
            </div>

            {/*
            <div className={styles.section}>
              <Typography variant="h6" gutterBottom>This courses include</Typography>
              <ul>
                <li><Typography variant="body1" gutterBottom>3.6 hours on-demand video</Typography></li>
                <li><Typography variant="body1" gutterBottom>3 downloadable resources</Typography></li>
                <li><Typography variant="body1" gutterBottom>Full lifetime access</Typography></li>
                <li><Typography variant="body1" gutterBottom>Certificate of completion</Typography></li>
              </ul>
            </div>

            <div className={styles.section}>
              <Typography variant="h6" gutterBottom>Who this course if for:</Typography>
              <Typography variant="body2" gutterBottom>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</Typography>
            </div>
            */}

            {course?.lectures.length > 0 ? <Lectures data={course?.lectures} /> : ''}

            {emptyQuiz ? '': 
            <div className={styles.section}>
              <Typography variant="h6" gutterBottom>Take the quiz</Typography>
              <NavLink to={`${certificate ? `/class/${course?._id}/certificate` : `/class/${course?._id}/quiz`}`} onClick={handleQuizClick}>
                <Typography component="div" variant="body2" className={styles.quiz}>Start the Quiz <Chip label="PREMIUM" variant="outlined" size="small" color="primary" /></Typography>
              </NavLink>
            </div>
            }
          </Grid>
          
          <Grid item xs={12} sm={4} md={3}>
            {course?.instructor ? <Instructor data={course?.instructor} /> : ''}
            <br/>
            {course?.materials.length > 0 ? <Materials data={course?.materials} /> : ''}
          </Grid>
        </Grid>
      </Container>

      <SnackBar messages={messages} open={snackOpen} close={handleSnackClose} url={url} label={label} />
    </div>
  )
}

export default memo(ClassSingle)