import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  SpinnerRoot: {
    display: 'flex',
    justifyContent: 'center',
    margin: '24px 0px 16px'
  }
}))

const Spinner = () => {
  const styles = useStyles()

	return (
		<div className={styles.SpinnerRoot}>
      <svg className="spinner" width="36px" height="36px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
       <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
      </svg>
    </div>
	)
}

export default memo(Spinner)