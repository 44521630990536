const initialState = {
  webinars: []
}

const webinars = (state = initialState, { type, payload }) => {
  switch (type) {
  	case "FETCH_ALL_WEBINARS":
      return {
        ...state,
        webinars: payload
      }

    default:
      return state
  }
}

export {
	webinars
}
