import React, { memo, useState, useEffect, useCallback } from 'react'
import { 
  useSelector,
  // useDispatch
} from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useManualQuery, useMutation } from 'graphql-hooks'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography
} from '@material-ui/core'
import { SUBSCRIBER_QUERY, USER_UPDATE_MOBILE_MUTATION } from '@gql/user'
import { Spinner, SnackBar } from '@common/components'
import { CommonStyle, FormStyle } from '@common/styles'

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: '#e50914',
    color: '#fff',

    "&:hover": {
      backgroundColor: '#e50914'
    }
  }
}))

const UserProfileUpdate = () => {
  const styles = useStyles()
  const Common = CommonStyle()
  const Form = FormStyle()
  const history = useHistory()
  const [getSubscriber] = useManualQuery(SUBSCRIBER_QUERY)
  const [updateMobile] = useMutation(USER_UPDATE_MOBILE_MUTATION)
  const user = useSelector(state => state.user)
  const [open, setOpen] = useState(false)
  const [messages, setMessages] = useState('')
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState({mobile: ''})

  const fetchSubscriber = useCallback(() => {
    getSubscriber({
      variables: {
        email: user.user.email
      }
    }).then( ({data, error}) => {
      setValues(data.subscriber)
    })
  }, [getSubscriber, user.user.email])

  useEffect(() => {
    fetchSubscriber()
  }, [fetchSubscriber])

  const handleInputChange = e => {
    const {name, value} = e.target
    setValues({...values, [name]: value})
  }

  const handleCancel = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleUserLogout = () => {
    history.push('/user/profile')
  }

  const userUpdateProfile = () => {
    const { mobile } = values

    setLoading(true)

    updateMobile({
      variables: {
        email: user.user.email,
        profile: {
          mobile
        }
      }
    }).then( ({data, error}) => {
      if (data?.updateMobile === null) {
        const { message } = error.graphQLErrors[0]
        setMessages(message)
        setLoading(false)
        return
      }

      setLoading(false)
      history.push('/user/profile')
    })
  }

  return (
    <div>
      <Container className={Common.content}>
        <Grid container className={Common.body}>
          <Grid container direction="row" alignItems="center">
            <Typography variant="h5">Update mobile number</Typography>
          </Grid>
        </Grid>

        <Divider className={Common.divider} />

        <Grid container spacing={4} className={Common.section}>
          <Grid item xs={12} md={4}><Typography variant="subtitle1">Mobile number</Typography></Grid>
          <Grid item xs={12} md={8}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="mobile"
                  placeholder="Enter mobile number to update"
                  name="mobile"
                  onChange={handleInputChange}
                  className={[Form.textField, Common.raleway].join(', ')}
                  value={values?.mobile}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider className={Common.divider} />

        {loading ? 
          <Spinner />
          :
          <Grid container spacing={3}>
            <Grid item xs={6} md={6}>
              <Button fullWidth variant="contained" color="secondary" className={styles.raleway} onClick={handleCancel}>Cancel</Button>
            </Grid>
            <Grid item xs={6} md={6}>
              <Button fullWidth variant="contained" color="primary" className={styles.raleway} onClick={() => userUpdateProfile()} disabled={!values.mobile}>Update</Button>
            </Grid>
          </Grid>
        }
      </Container>

      <SnackBar messages={messages} open={open} close={handleClose} />

      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Cancel profile update</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to cancel profile update? You will lose your unsaved information.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button onClick={handleUserLogout} color="primary" autoFocus>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default memo(UserProfileUpdate)