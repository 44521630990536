import React, { memo } from 'react'

const ContentPlaceholder = () => {
	return (
		<div className="content-placeholder">
      <div className="content-placeholder-title">
        <span className="content-placeholder-background"></span>
      </div>

      <div className="content-placeholder-container">
        <div className="content-placeholder-item">
          <span className="content-placeholder-background"></span>
        </div>
        <div className="content-placeholder-item">
          <span className="content-placeholder-background"></span>
        </div>
        <div className="content-placeholder-item">
          <span className="content-placeholder-background"></span>
        </div>
        <div className="content-placeholder-item">
          <span className="content-placeholder-background"></span>
        </div>
        <div className="content-placeholder-item">
          <span className="content-placeholder-background"></span>
        </div>
        <div className="content-placeholder-item">
          <span className="content-placeholder-background"></span>
        </div>
      </div>
    </div>
	)
}

export default memo(ContentPlaceholder)