const USER_ENROLL_MUTATION = `mutation enrollWebinar($_id: String!, $email: String!) {
  enrollWebinar(_id: $_id, email: $email) {
    enrolls
  }
}
`

export {
  USER_ENROLL_MUTATION
}
