import React, { memo, useState, useEffect } from 'react'
// import { useManualQuery } from 'graphql-hooks'
import { useSelector } from 'react-redux'
import {
  Banner,
  ContentPlaceholder,
  FeaturedLeft,
  MovieStrip,
  // SnackBar
} from '@common/components'
import { Style } from '@common/style'
// import { FETCH_ALL_CLASSES } from '@gql/course'
import { data } from '@mock/data'

const ClassesList = () => {
  const styles = Style()
  const state = useSelector(state => state)
  const [loading, setLoading] = useState(false)
  // const [courses, setCourses] = useState('')
  const [latest, setLatest] = useState('')
  const { browse: { banner } } = data

  useEffect(() => {
    setLoading(true)
    const result = state?.courses?.courses.reduce((r, o) => o.tm_published > r.tm_published ? o : r)
    setLatest(result)
    setLoading(false)
  }, [state.courses.courses])

  return (
    <div>
      <div style={{marginTop: '65px'}}>
        <Banner data={banner} trailer={latest} />
      </div>

      <div className={styles.content}>
        {loading ? <ContentPlaceholder /> : <MovieStrip data={state.courses.courses.filter(c => c.category.name === 'LeadershipCORE')} title="LeadershipCORE" />}

        <FeaturedLeft data={latest} />
        
        {/*<MovieStrip data={data.classesList.classes} title="Continue watching" />*/}
        {/*<MovieStrip data={data.classesList.classes} title="Recommended" />*/}

        {/*<CourseDialog selectedValue={selectedValue} open={open} onClose={handleClose} />*/}

        {/*<SnackBar messages={messages} open={open} close={handleClose} />*/}
      </div>
    </div>
  )
}

export default memo(ClassesList)