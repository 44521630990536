import React, { memo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js'
import { useStripe, useElements, Elements, CardElement } from "@stripe/react-stripe-js"
import CardInput from './CardInput'
import { useMutation } from 'graphql-hooks'
import { 
  Button,
  Grid,
  TextField
} from '@material-ui/core'
import { USER_CREATE_SUBSCRIPTION, USER_UPDATE_ACCOUNT_MUTATION } from '@gql/user'
import { SnackBar, Spinner } from '@common/components'

const stripePromise = loadStripe(`${process.env.REACT_APP_PUBLISHABLE_KEY}`)

const CheckoutForm = ({ signUpEmail, returnUrl }) => {
  const [email, setEmail] = useState(`${signUpEmail}`)
  const [coupon, setCoupon] = useState('')
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector(state => state.user.user)
  const [createSubscription] = useMutation(USER_CREATE_SUBSCRIPTION)
  const [updateAccount] = useMutation(USER_UPDATE_ACCOUNT_MUTATION)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [messages, setMessages] = useState(null)
  const [open, setOpen] = useState(false)

  const handleSubmitSub = async (event) => {
    setLoading(true)
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        email: email,
      },
    });

    if (result.error) {
      setMessages(result.error.message)
      setLoading(false)
      setOpen(true)
    } else {
      createSubscription({
        variables: {
          email,
          payment_method: result.paymentMethod.id,
          plan_type: 'premium',
          coupon
        }
      }).then( ({data, error}) => {
        // if failed, informed the user
        if (data?.createSubscription === null) {
          const { message } = error.graphQLErrors[0]
          setMessages(message)
          setLoading(false)
          setOpen(true)
          return
        }
        
        // if success, we need to update the subscriber plan_type
        updateAccount({
          variables: {
            email,
            profile: {
              plan_type: 'premium',
              tm_subscription: new Date().getTime().toString()
            }
          }
        }).then( ({data, error}) => {
          if (data?.updateAccount === null) {
            const { message } = error.graphQLErrors[0]
            setMessages(message)
            setLoading(false)
            return
          }

          // We also need to update the plan_type to 'premium' of logged in user information in the local storage
          const updateLocalUser = {
            ...user,
            plan_type: 'premium'
          }
          dispatch({
            type: 'USER_ACCOUNT_UPGRADE_SUCCESS',
            payload: updateLocalUser
          })
        })


        // finally, we display the message to the subscriber of account upgrade success and redirect back to profile page
        setMessages('Account upgrade successful! Please enjoy all of our premium contents.')
        setLoading(false)
        setOpen(true)
        setSuccess(true)
        setEmail(null)
        elements.getElement(CardElement).clear()

        setTimeout(function() {
          history.push('/user/profile')
        }, 2000);

        // const res = await axios.post('http://localhost:3000/sub', {'payment_method': result.paymentMethod.id, 'email': email})
        // const {client_secret, status} = res.data
        // if (status === 'requires_action') {
        //  stripe.confirmCardPayment(client_secret).then(function(result) {
        //    if (result.error) {
        //      setMessages(result.error)
        //      setLoading(false)
        //    } else {
        //      setMessages('Subscription successful!')
        //      setLoading(false)
        //    }
        //  })
        // } else {
        //  setMessages('Subscription successful!')
        //  setLoading(false)
        // }
      })
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <form className="Form">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            placeholder='Email *'
            margin='normal'
            variant='outlined'
            type='email'
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <CardInput />
        </Grid>
        <Grid item xs={12}>
          <TextField
            placeholder='Enter your coupon code'
            margin='normal'
            variant='outlined'
            type='coupon'
            value={coupon}
            onChange={(e) => setCoupon(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <p>Subscription is configured for automatic renewal. To prevent any charges, kindly ensure to cancel it before the upcoming renewal date.</p>
        </Grid>
        <Grid item xs={12}>
        {loading ? <Spinner /> : success ? <Button component={Link} to={returnUrl} variant="contained" color="primary" fullWidth>Return to login</Button> : <Button variant="contained" color="primary" fullWidth onClick={handleSubmitSub}>Subscribe</Button>}
        </Grid>
      </Grid>

      <SnackBar messages={messages} open={open} close={handleClose} />
    </form>
  );
}

const StripeCheckoutForm = ({ email, returnUrl }) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm signUpEmail={email} returnUrl={returnUrl} />
    </Elements>
  )
}

export default memo(StripeCheckoutForm)
