import EventEmitter from "events";
import { GraphQLClient } from "graphql-hooks";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { v4 as uuidv4 } from "uuid";

export default class GqlClient extends EventEmitter {
  subClient = null;
  client_id = uuidv4();
  init(token) {
    let uri = process.env.REACT_APP_API_PATH;
    uri = uri.replace("http://", "ws://");
    uri = uri.replace("https://", "wss://");

    if (token) {
      this.subClient = new SubscriptionClient(uri, {
        reconnect: true,
        connectionParams: {
          "x-access-token": token,
          "x-client-id": this.client_id,
        },
      });
    }

    this.client = new GraphQLClient({
      url: process.env.REACT_APP_API_PATH,
      logErrors: process.env.NODE_ENV === "development" || false,
      headers: token
        ? {
            "x-access-token": token,
            "x-client-id": this.client_id,
          }
        : {},
      onError: this.onGraphqlError,
      subscriptionClient: this.subClient,
    });
  }

  onGraphqlError({ result }) {
    if (
      !result.data &&
      result.error &&
      result.error.graphQLErrors &&
      result.error.graphQLErrors.length > 0
    ) {
      const un_auth = result.error.graphQLErrors.find(
        (err) => err.extensions && err.extensions.code === "UNAUTHENTICATED"
      );
      if (un_auth) {
        this.emit("authError");
      }
    }
  }
}
