import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { Container, CssBaseline, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import StripeCheckoutForm from '@signup/components/Stripe/StripeCheckoutForm'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100% - 64px)',
    display: 'flex',
    alignItems: 'center'
  },
}))

const UserUpgradeSubscription = () => {
  const styles = useStyles()
  const signup = useSelector(state => state.signup)

  return (
    <div className={styles.root}>
      <CssBaseline />
      <Container component="main" maxWidth="xs">
        <Typography component="h1" variant="h5">Upgrade subscription</Typography>
        <StripeCheckoutForm email={signup.email} returnUrl="user/profile" />
      </Container>
    </div>
  )
}

export default memo(UserUpgradeSubscription)
