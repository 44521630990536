import React, { useContext } from 'react'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FormContext } from './FormContext';

const useStyles = makeStyles((theme) => ({
  textField: {
    border: 'none',
    borderRadius: '0',
    outline: 'none',

    '& fieldset': {
      border: 'none'
    },

    '&:hover fieldset': {
      border: 'none'
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },
}));

const Input = ({ field_id, field_name, field_label, field_placeholder, field_value, field_type, field_options, field_pattern }) => {
	const styles = useStyles()
	const { handleChange } = useContext(FormContext)

	return (
		<TextField
      variant="outlined"
      margin="normal"
      required
      fullWidth
      id={field_id}
      placeholder={field_placeholder ? field_placeholder : ' '}
      name={field_name}
      type={field_type}
      autoComplete={field_name}
      autoFocus
      className={styles.textField}
      value={field_value}
      pattern={field_pattern}
      onChange={event => handleChange(field_id, event)}
    />
	)
}

export default Input