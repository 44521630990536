import React, { memo, useState, useEffect } from 'react'
// import { useManualQuery } from 'graphql-hooks'
import {
  // useDispatch,
  useSelector
} from 'react-redux'
import { MovieStrip } from '@common/components'
import { Style } from '@common/style'
// import { ContentPlaceholder } from '@common/components'
// import { SUBSCRIBER_QUERY } from '@gql/user'
// import { data } from '@mock/data'

const MyclassesList = () => {
  const classes = Style()
  // const [loading, setLoading] = useState(false)
  const [addedCourses, setAddedCourses] = useState('')
  const [progress, setProgress] = useState('')
  const [completed, setCompleted] = useState('')
  const state = useSelector(state => state)
  let arrayFiltered = []

  useEffect(() => {
    // subscriber unique course progress course id
    const subscriberUniqueCourseProgress = [...new Set(state.user.user.course_progress.map(item => item.courseId))];

    // filtered courses base on subscriber unique course progress course id
    const filteredSubscriberUniqueCourse = state.courses.courses.filter(item => subscriberUniqueCourseProgress.includes(item._id))

    // get the last lecturer video of each courses
    const lastCourseLecture = state.courses.courses.map(u => u.lectures[u.lectures.length -1])

    // define empty completedCourseId array 
    let completedCourseId = []

    // Iterate subscriber course progress courseId with lastCourseLecture to calculate video duration
    // video duration above 90% will be consider the course is fully completed
    state.user.user.course_progress.map(p => {
      lastCourseLecture.map(e => {
        if(p.lectureId === e?._id) { // course with empty lecture will be skipped
          if ( (p.progress / e.video_duration) * 100 > 90) {
            completedCourseId.push(p.courseId)
          }
        }
      })
    })

    // iterate courses to filter out by completedCourseId
    const completedCourse = state.courses.courses.filter(c => completedCourseId.includes(c._id))
    
    setCompleted(completedCourse)


    const userCourses = state.courses.courses.filter(item => state.user.user.courses.includes(item._id))
    setAddedCourses(userCourses)

    state.user.user.course_progress.forEach(obj => {
      const item = arrayFiltered.find(thisItem => thisItem.courseId === obj.courseId);
      if (item) {
        if (item.progress < obj.progress) {
          item.progress = obj.progress;
        }          
        return;
      }
      
      arrayFiltered.push(obj)
    })

    let merged = [];

    for(let i=0; i<state.courses.courses.length; i++) {
      merged.push({
        ...state.courses.courses[i], 
        ...(arrayFiltered.find((itmInner) => itmInner.courseId === state.courses.courses[i]._id))}
      );
    }

    setProgress(merged.filter(m => !completedCourseId.includes(m.courseId)).filter(m => m.courseId && m.progress))
  }, [state.user.user.courses])

  return (
    <div>
      <div style={{marginTop: '65px'}} />
      <div className={classes.content}>
        {addedCourses && addedCourses.length > 0 ? <MovieStrip data={addedCourses} title="Added Classes" /> : ''}
        {progress && progress.length > 0 ? <MovieStrip data={progress} title="Continue watching" /> : ''}
        {completed && completed.length > 0 ? <MovieStrip data={completed} title="Completed course" /> : ''}
        {state.courses.courses ? <MovieStrip data={state.courses.courses} title="Recommended" /> : ''}
      </div>
    </div>
  )
}

export default memo(MyclassesList)