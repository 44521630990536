import React, { memo } from 'react'
import { NavLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import {
  DialogContent,  
  Dialog,
  Typography,
  Button,
  Divider,
  Grid  
} from '@material-ui/core'
import { PlayArrow, Add } from '@material-ui/icons'

const useStyle = makeStyles((theme) => ({
  root: {
    padding: '0'
  },
  content: {
    padding: '0 0 32px !important',

    '& img': {
      width: '100%'
    }
  },
  contentText: {
    padding: '0 32px'
  },
  tagLabel: {
    paddingRight: '4px'
  },
  tagItem: {
    fontWeight: 500
  },
  divide: {
    margin: '12px 0'
  },
  buttonWrap: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  },
  button: {
    margin: theme.spacing(1),
  },
  buttonAdd: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 8px',
    width: '35px',
    height: '35px',
    border: '2px solid #484848',
    borderRadius: '50%'
  },
  buttonAddIcon: {
    fontSize: '26px',
    color: '#484848'
  }
}))

const CourseDialog = (props) => {
  const { onClose, selectedValue, open } = props
  const classes = useStyle()

  const handleClose = () => {
    onClose(selectedValue)
  }

  // const handleListItemClick = (value) => {
  //   onClose(value)
  // }

  return (
    <Dialog open={open} onClose={handleClose} scroll="body" className={classes.root}>
			<DialogContent className={classes.content}>
        <img src="https://i.ytimg.com/vi/yu-RKkb3tdk/hqdefault.jpg?sqp=-oaymwEZCPYBEIoBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLA--m5az9xF3W8v1u1ThRdSX3GxSg" alt="" />
				<div className={classes.contentText}>
          <Typography variant="h6" gutterBottom>Leadership Transformation Amidst COVID-19</Typography>
          <Typography variant="subtitle2" gutterBottom>A set of creative courses and programmes to help leaders and leadership teams rethink their role, gain new skills and learn how to unite their organisations to navigate future challenges and be effective in Industry 4.0.</Typography>
          
          <div className={classes.buttonWrap}>
            <Button
              component={NavLink} to="/class/video/1111"
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<PlayArrow />}
            >
              Play
            </Button>
            <Button className={classes.buttonAdd}>
              <Add className={classes.buttonAddIcon} />
            </Button>
          </div>
          
          <Divider variant="fullWidth" className={classes.divide} />

          <Typography variant="h6" gutterBottom>Details</Typography>
          <div>
            <span className={classes.tagLabel}>Instructor:</span>
            <span className={classes.tagItem}>Michael Dai</span>
          </div>

          <div>
            <span className={classes.tagLabel}>Practice tests:</span>
            <span className={classes.tagItem}>2</span>
          </div>

          <div>
            <span className={classes.tagLabel}>Questions:</span>
            <span className={classes.tagItem}>12</span>
          </div>

          <div>
            <span className={classes.tagLabel}>Language:</span>
            <span className={classes.tagItem}>English</span>
          </div>

          <div>
            <span className={classes.tagLabel}>Videos:</span>
            <span className={classes.tagItem}>5</span>
          </div>

          <div>
            <span className={classes.tagLabel}>Genres:</span>
            <span className={classes.tagItem}>Digital, </span>
            <span className={classes.tagItem}>Marketing</span>
          </div>

          <Divider variant="fullWidth" className={classes.divide} />

          <Typography variant="h6" gutterBottom>Similar like this</Typography>

          <Grid container spacing={2}>
            <Grid item sm={4}>
              <img src="https://i.ytimg.com/vi/adiEAaCnPXU/hqdefault.jpg?sqp=-oaymwEZCPYBEIoBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLBn7SeiUzlS2PvlXzxsrW6N65rcCw" alt="" />
              <Typography variant="caption">Who is Consulus</Typography>
            </Grid>
            <Grid item sm={4}>
              <img src="https://i.ytimg.com/vi/umeZP0CxqUQ/hqdefault.jpg?sqp=-oaymwEZCPYBEIoBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLC0SN7tEYr4ueMxWTBnCBV6j90L4Q" alt="" />
              <Typography variant="caption">How has COVID-19 affected leadership? Survey Results</Typography>
            </Grid>
            <Grid item sm={4}>
              <img src="https://i.ytimg.com/vi/_zNVg3IeMGY/hqdefault.jpg?sqp=-oaymwEZCPYBEIoBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLBmu1F_DOURzwEj-o87xTdhIOThFQ" alt="" />
              <Typography variant="caption">Being a holistic leader through the H.E.R.O Leadership Model</Typography>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
  	</Dialog>
  )
}

CourseDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
}

export default memo(CourseDialog)