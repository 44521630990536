import React, { memo, useState, useEffect } from 'react'
import { useParams, NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import {
	Button,
	Card,
	CardActions,
	CardContent,
	Chip,
	Container,
	Grid,
	Typography
} from '@material-ui/core'
import Instructor from '@classes/components/Instructor'
import { truncateString } from '@helpers'

const useStyle = makeStyles((theme) => ({
	content: {
    padding: '32px 0',

    [theme.breakpoints.down('md')]: {
      padding: '32px 44px',
    },

    [theme.breakpoints.down('sm')]: {
      padding: '32px 16px',
    }
  },
  body: {
    padding: '56px 0'
  },
  root: {
  	display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  instructorCourses: {
  	display: 'grid',
	  gridTemplateColumns: '1fr 1fr 1fr',
	  gridTemplateRows: '1fr 1fr',
	  gap: '32px 32px',
	  gridAutoFlow: 'row',
  },
  courseContent: {
  	display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  }
}))

const InstructorProfile = () => {
	const styles = useStyle()
	const state = useSelector(state => state)
	const { instructorId } = useParams()
	const [instructor, setInstructor] = useState(null)
	const [course, setCourse] = useState(null)

	useEffect(() => {
		const filteredCourse = state.courses.courses.filter(c => c.instructor._id === instructorId)
		setCourse(filteredCourse)
	}, [instructorId, state.courses.courses])

	useEffect(() => {
		const filteredInstructor = state.instructors.instructors.filter(c => c._id === instructorId)
		setInstructor(filteredInstructor[0])
	}, [instructorId, state.instructors.instructors])

	return (
		<div>
			<Container className={styles.content}>
				<Grid container spacing={4} className={styles.body}>
					<Grid item xs={12} sm={8} md={10}>
						<Typography variant="h4" gutterBottom>About me</Typography>
						<div dangerouslySetInnerHTML={{__html: instructor?.portfolio}} />
						<br/>
						<Typography variant="h5" gutterBottom>My classes</Typography>
						<div className={styles.instructorCourses}>
							{course?.map(c => {
								return ( 
									<Card className={styles.root} key={c._id}>
							      <CardContent className={styles.courseContent}>
							        <Typography className={styles.title} color="textSecondary" gutterBottom>{c.category.name}</Typography>
							        <Typography variant="h6">{c.name}</Typography>
							        <Typography className={styles.pos} color="textSecondary" dangerouslySetInnerHTML={{__html: truncateString(c.summary, 60)}}></Typography>
						          <div>
                				{c?.tags.map((tag, i) => {
                  				return (
                    				<Chip label={tag} color="primary" className="tag" size="small" key={i} />
                  				)
                				})}
            					</div>
							      </CardContent>
							      <CardActions>
							        <Button component={NavLink} to={`/class/${c._id}`} color="primary" className="button" size="small" variant="contained" fullWidth>Start course</Button>
							      </CardActions>
							    </Card>
								)
							})}
						</div>
					</Grid>
					<Grid item xs={12} sm={4} md={2}>
						{instructor ? <Instructor data={instructor} /> : ''}
					</Grid>
				</Grid>
			</Container>
		</div>
	)
}

export default memo(InstructorProfile)
