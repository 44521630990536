const FETCH_CATEGORIES = `query categories($skip: Int, $limit: Int) {
  categories(skip: $skip, limit: $limit) {
    _id
    name
    parent
  }
}
`

export {
  FETCH_CATEGORIES
}
