import React, { memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from 'graphql-hooks'
// import { NavLink } from 'react-router-dom'
import {
  Button,
  IconButton,
} from '@material-ui/core'
import { 
  Add,
  Check,
  // PlayArrow
} from '@material-ui/icons'
import { SnackBar, ModalTrailer } from '@common/components'
import { ADD_COURSE_MUTATION, DELETE_COURSE_MUTATION } from '@gql/course'
import Slider from 'react-slick'
import './style.css'
import './slick.css'
import './slick-theme.css'

const Banner = ({ data }) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user.user)
  const [course, setCourse] = useState('')
  const [open, setOpen] = useState(false)
  const [snackOpen, setSnackOpen] = useState(false)
  const [trailer, setTrailer] = useState(null)
  const [messages, setMessages] = useState('')
  const [addCourse] = useMutation(ADD_COURSE_MUTATION)
  const [deleteCourse] = useMutation(DELETE_COURSE_MUTATION)

  const handleSaveCourse = (course) => {
    setMessages(`${course.name} is added to your list`)
    setSnackOpen(true)

    dispatch({
      type: "ADD_TO_LIST",
      payload: [...user.courses, course._id]
    })

    addCourse({
      variables: {
        email: user.email,
        courseId: course._id
      }
    }).then( ({data, error}) => {
      setMessages(`${course.name} is added to your list`)
      setSnackOpen(true)
    })
  }

  const handleRemoveCourse = (course) => {
    for( var i = 0; i < user.courses.length; i++) {
      if ( user.courses[i] === course._id) {
        user.courses.splice(i, 1); 
      }
    }

    deleteCourse({
      variables: {
        email: user.email,
        courseId: course._id
      }
    }).then( ({data, error}) => {
      // console.log(data)
      setMessages(`${course.name} is removed from your list`)
      setSnackOpen(true)
    })

    dispatch({
      type: "REMOVE_FROM_LIST",
      payload: [...user.courses]
    })
  }

  const handleClickOpen = (d) => {
    setOpen(true);
    setTrailer(d)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSnackbar = () => {
    setSnackOpen(false)
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  return (
    <div>
      <Slider {...settings}>
        {data.map((d, i) => {
          return (
            <div key={i}>
              <div style={{
                backgroundColor: '#222',
                height: '448px',
                backgroundSize: "cover",
                backgroundImage: `url(/streams/${d._id}/${encodeURIComponent(d.banner).replace(/'/g,"%27").replace(/"/g,"%22")})`,
                backgroundPosition: "center center"}}>
                <div className="banner__content">
                  <h1 className="banner__title">{d.name}</h1>
                  <div className="banner__buttons">
                    <Button variant="contained" color="primary" onClick={() => handleClickOpen(d)}>Play trailer</Button>&nbsp;&nbsp;&nbsp;
                    {user.courses.includes(d._id) ? <IconButton aria-label="delete" size="small" className="course-checked" onClick={() => handleRemoveCourse(d)}><Check /></IconButton> : <Button variant="contained" color="primary" className="button" startIcon={<Add />} onClick={() => handleSaveCourse(d)}>Add to My List</Button>}
                    {/*<Button component={NavLink} to='/my-class' variant="contained" color="primary">My List</Button>*/}
                  </div>
                  <p className="banner__description" dangerouslySetInnerHTML={{__html: d.summary}}></p>
                </div>
                <div className="banner--fadebottom" />
              </div>
            </div>
          )
        })}
      </Slider>

      <ModalTrailer url={`/media/trailer?.trailer_video}`} open={open} close={handleClose} />
      <SnackBar messages={messages} open={snackOpen} close={handleCloseSnackbar} />
    </div>
  )
}

export default memo(Banner)