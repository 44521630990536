import { makeStyles } from '@material-ui/core/styles'

const Common = makeStyles((theme) => ({
	raleway: {
		fontFamily: `"Raleway", "sans-serif"`,

		[theme.breakpoints.down('sm')]: {
      fontSize: '1.1rem'
    }
  },
  content: {
    padding: '32px 0'
  },
  body: {
    padding: '56px 0'
  },
  divider: {
    margin: theme.spacing(2, 0),
    backgroundColor: '#404040'
  },
  section: {
    marginBottom: '16px'
  },
}))

export default Common

