import { makeStyles } from '@material-ui/core/styles'

const signup = makeStyles((theme) => ({
  signUpWrapper: {
    paddingRight: '24px',
    paddingLeft: '24px',
    height: '100%',
    color: '#fff'
  },
	root: {
    padding: '56px 0',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    color: '#fff',

    [theme.breakpoints.down('sm')]: {
      paddingRight: '16px',
      paddingLeft: '16px',
    },
	},
	stepContext: {
    // display: 'flex',
    // margin: '16px 0',
    // alignItems: 'flex-start',
    // flexDirection: 'column',
    flex: '1',
    padding: '32px 0',

    "& ul": {
    	margin: '32px 0',

    	"& li": {
    		padding: '4px 0',
    		lineHeight: '18px'
    	}
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
	},
  stepAction: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '16px 0',

    "& button": {
      backgroundColor: '#00916e',

      "&:hover": {
        backgroundColor: '#00916e'
      }
    }
  },
  stepTerms: {
    padding: '16px 0',
    borderTop: '1px solid #d0cfcf'
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    alignItems: 'center'
  },
	button: {
		padding: '12px 16px',
    minWidth: '340px',
    minHeight: '48px',
    backgroundColor: '#00916e',
    fontSize: '16px',
    fontWeight: '400',
    color: '#fff',
    lineHeight: '24px',
    textAlign: 'center',
    textDecoration: 'none',
    textTransform: 'uppercase',
    outline: 'none',
    border: 'none',
    alignSelf: 'center',
    cursor: 'pointer',

    "&:hover": {
      backgroundColor: '#00916e'
    }
	},
  account: {
    "& form": {
      display: 'flex',
      flexDirection: 'column',

      "& input": {
        width: '400px',

        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
      },

      "& .MuiTextField-root": {
        marginBottom: '16px',

        [theme.breakpoints.down('sm')]: {
          width: '100%'
        }
      }
    }
  },
  categories: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // gridTemplateColumns: '200px 200px 200px',
    // gridGap: '10px',
    // marginBottom: '16px',

    "& .category": {
      padding: '8px 16px',
      backgroundColor: '#f0f0f0',
      color: '#000',
      textAlign: 'left',
      cursor: 'pointer',
      minWidth: '400px',

      "&.active": {
        backgroundColor: '#00916e',
        fontWeight: '500',
        color: '#fff',
      }
    },

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '32% 32% 32%',
    }
  },
  checkout: {
    width: "500px",

    [theme.breakpoints.down('sm')]: {
      width: "100%",
    }
  },
  plan: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '50px 0',
    

    "& > div": {
      flexGrow: '1',
      flexBasis: '0',
      margin: '0 8px'
    }
  },
  
}))

export default signup