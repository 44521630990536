const USER_REGISTER_MUTATION = `mutation register($email: String!, $password: String!) {
  register(email: $email, password: $password) {
    email
  }
}
`

const USER_LOGIN_MUTATION = `mutation login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    _id
    email
    fullname
    mobile
    plan_type
    main_account
    courses
    course_progress {
      courseId
      lectureId
      progress
      tm_updated
    }
    favourite_categories
    accessToken
    idToken
    refreshToken
  }
}
`
const USER_UPDATE_CATEGORIES_MUTATION = `mutation updateSubscriber($email: String!, $profile: SubscriberProfile!) {
  updateSubscriber(email: $email, profile: $profile) {
    email
    favourite_categories
  }
}
`

const USER_UPDATE_PROFILE_MUTATION = `mutation updateSubscriber($email: String!, $profile: SubscriberProfile!) {
  updateSubscriber(email: $email, profile: $profile) {
    email
    fullname,
    company,
    designation,
    country,
    city,
    language,
    profile,
    stw_vision,
    stw_outcome,
    email_subscription
  }
}
`

const USER_UPDATE_MOBILE_MUTATION = `mutation updateSubscriber($email: String!, $profile: SubscriberProfile!) {
  updateSubscriber(email: $email, profile: $profile) {
    mobile
  }
}
`

const USER_UPDATE_ACCOUNT_MUTATION = `mutation updateSubscriber($email: String!, $profile: SubscriberProfile!) {
  updateSubscriber(email: $email, profile: $profile) {
    plan_type
  }
}
`

const UPDATE_COURSE_PROGRESS = `mutation updateCourseProgress($email: String!, $courseProgress: CourseProgressInput!) {
  updateCourseProgress(email: $email, courseProgress: $courseProgress) {
    email
    course_progress {
      courseId
      lectureId
      progress
      tm_updated
    }
  }
}
`

const USER_CREATE_SUBSCRIPTION = `mutation createSubscription($email: String!, $payment_method: String!, $plan_type: String!, $coupon: String) {
	createSubscription(email: $email, payment_method: $payment_method, plan_type: $plan_type, coupon: $coupon) {
		email
	}
}
`

const USER_CANCEL_SUBSCRIPTION = `mutation cancelSubscription($email: String!) {
  cancelSubscription(email: $email) {
    email
  }
}
`

const USER_FORGOT_PASSWORD_MUTATION = `mutation forgotPassword($email: String!) {
  forgotPassword(email: $email)
}
`

const USER_RESET_PASSWORD_MUTATION = `mutation resetPassword($email: String!, $code: String!, $password: String!) {
  resetPassword(email: $email, code: $code, password: $password)
}
`

const VERIFY_SUBSCRIBER_TOKEN = `mutation verifySubscriberToken($token: String!) {
  verifySubscriberToken(token: $token) {
    _id
    email
  }
}
`

export {
  USER_REGISTER_MUTATION,
  USER_LOGIN_MUTATION,
  USER_UPDATE_PROFILE_MUTATION,
  USER_UPDATE_MOBILE_MUTATION,
  USER_UPDATE_ACCOUNT_MUTATION,
  USER_UPDATE_CATEGORIES_MUTATION,
  UPDATE_COURSE_PROGRESS,
  USER_CREATE_SUBSCRIPTION,
  USER_CANCEL_SUBSCRIPTION,
  USER_FORGOT_PASSWORD_MUTATION,
  USER_RESET_PASSWORD_MUTATION,
  VERIFY_SUBSCRIBER_TOKEN
}
