import React, { memo } from 'react'
import {
	Container,
  Typography,
} from '@material-ui/core'
import { CommonStyle } from '@common/styles'
import pagesStyle from './style'

const ConsulusIpsCopyrights = () => {
	const Pages = pagesStyle()
	const Common = CommonStyle()

	return (
		<div className={Pages.root}>
			<Container className={Common.content}>
				<div className={Common.body}>
					<Typography variant="h6" gutterBottom>CONSULUS CLASS INTELLECTUAL PROPERTIES AND COPYRIGHTS</Typography>
					<Typography variant="subtitle2" gutterBottom>Terms & Conditions</Typography>
					<br />
					<Typography variant="body2" gutterBottom>Consulus Class is an online learning platform of Consulus methods for personal and organizational development. The intellectual properties (IPs) of Consulus remain the IPs of Consulus whether or not learners complete the courses, receive certificates of completion, or get certified to deploy Consulus proprietary tools and frameworks.</Typography>
					<br />
					<Typography variant="body2" gutterBottom>Consulus Class respects the intellectual property rights of others and expects Course Content Creator Partner (CCCP) on Consulus Class to do the same. When the CCCP submits content on Consulus Class, the CCCP makes the promise that, to the best of the CCCP’s knowledge, information and belief, the content submitted by the CCCP does not infringe IPs of others, nor contain defamatory or unlawful material.</Typography>
				</div>
			</Container>
		</div>
	)
}

export default memo(ConsulusIpsCopyrights)
