import { makeStyles } from '@material-ui/core/styles'

const pages = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      paddingRight: '44px',
      paddingLeft: '44px',
    }
  }
}))

export default pages