import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
	Typography,
} from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'

const useStyle = makeStyles((theme) => ({
	download: {
    margin: '8px 0',
    textDecoration: 'none',

    "& li": {
    	display: 'flex',
    	fontSize: '20px',

    	"& a": {
    		display: 'flex',
    		alignItems: 'center',
    		fontSize: '14px'
    	}
    }
	},
}))

const Materials = ({ data }) => {
	const styles = useStyle()

	return (
		<>
			<Typography variant="h6" gutterBottom>Download course materials</Typography>
			<ul className={styles.download}>
				{data && data.map ((m, i) => {
					return (
						<li key={i}><a href={m} download target="_blank" rel="noopener noreferrer"><GetAppIcon />&nbsp;  Download material</a></li>
					)
				})}
			</ul>
		</>
	)
}

export default memo(Materials)