import React, { useEffect } from "react"
import { useSelector } from 'react-redux'
import { ZoomMtg } from "@zoomus/websdk"
const crypto = require("crypto") // crypto comes with Node.js

const Zoom = ({ meeting }) => {
  const { user } = useSelector(state => state.user)
	const apiKey = process.env.REACT_APP_ZOOM_API_KEY
	const apiSecret = process.env.REACT_APP_ZOOM_API_SECRET
	const meetingNumber = meeting.meeting_id
	const leaveUrl = "/webinars" // our redirect url
	const userName = user.fullname
	const userEmail = user.email
	const passWord = meeting.password
	let signature = ""

	generateSignature(apiKey, apiSecret, meetingNumber, 0).then((res) => {
	  signature = res;
	}); // need to generate based on meeting id - using - role by default 0 = javascript

  const initiateMeeting = () => {
    generateSignature(apiKey, apiSecret, meetingNumber, 0).then((res) => {
      ZoomMtg.init({
        leaveUrl: leaveUrl,
        isSupportAV: true,
        success: (success) => {
          ZoomMtg.join({
            signature: res,
            meetingNumber: meetingNumber,
            userName: userName,
            apiKey: apiKey,
            userEmail: userEmail,
            passWord: passWord,
            success: (success) => {
              console.log(success);
            },
            error: (error) => {
              console.log(error);
            },
          });
        },
        error: (error) => {
          console.log(error);
        },
      });
    });
  };

  // loading zoom libraries before joining on component did mount
  useEffect(() => {
    showZoomDIv();
    ZoomMtg.setZoomJSLib("https://source.zoom.us/1.9.7/lib", "/av");
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    initiateMeeting();
  }, []);

  function generateSignature(apiKey, apiSecret, meetingNumber, role) {
	  return new Promise((res, rej) => {
	    // Prevent time sync issue between client signature generation and zoom
	    const timestamp = new Date().getTime() - 30000;
	    const msg = Buffer.from(apiKey + meetingNumber + timestamp + role).toString(
	      "base64"
	    );
	    const hash = crypto
	      .createHmac("sha256", apiSecret)
	      .update(msg)
	      .digest("base64");
	    const signature = Buffer.from(
	      `${apiKey}.${meetingNumber}.${timestamp}.${role}.${hash}`
	    ).toString("base64");

	    res(signature);
	  });
	}

  const showZoomDIv = () => {
    document.getElementById("zmmtg-root").style.cssText += "display: block !important"
  };

  return (
  	<div className="App">Zoom</div>
	)
};

export default Zoom;