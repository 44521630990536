import React, { memo } from 'react'
import {
	Container,
  Typography,
} from '@material-ui/core'
import { CommonStyle } from '@common/styles'
import pagesStyle from './style'

const MembershipPlan = () => {
	const Pages = pagesStyle()
	const Common = CommonStyle()

	return (
		<div className={Pages.root}>
			<Container className={Common.content}>
				<div className={Common.body}>
					<Typography variant="h6" gutterBottom>MEMBERSHIP PLAN</Typography>
					<br />
					<Typography variant="body2" gutterBottom>Currently, there are 2 membership plans available for Beta Consulus Class:</Typography>
					<ol>
						<li><Typography variant="body2" gutterBottom>Shape the World Changemaker Plan: FREE access to Changemakers’ classes for those who sign up as members of Shape the World Changemakers movement.</Typography></li>
						<li><Typography variant="body2" gutterBottom>PREMIUM Plan: for Consulites (aka consultants at Consulus) able to access ALL classes available on Consulus Class.</Typography></li>
					</ol>
				</div>
			</Container>
		</div>
	)
}

export default memo(MembershipPlan)
