import React, { memo, useState, useEffect, useCallback } from 'react'
import { useManualQuery } from 'graphql-hooks'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Banner, ContentPlaceholder, FeaturedLeft, MovieStrip, SnackBar } from '@common/components'
import { SUBSCRIBER_QUERY } from '@gql/user'
import { FETCH_ALL_COURSES } from '@gql/course'
import { FETCH_INSTRUCTORS } from '@gql/instructor'
import { Style } from '@common/style'

const BrowseList = () => {
  const styles = Style()
  const dispatch = useDispatch()
  const history = useHistory()
  const state = useSelector(state => state)
  const [getSubscriber] = useManualQuery(SUBSCRIBER_QUERY)
  const [getCourses] = useManualQuery(FETCH_ALL_COURSES)
  const [getInstructors] = useManualQuery(FETCH_INSTRUCTORS)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [courses, setCourses] = useState('')
  const [progress, setProgress] = useState('')
  const [messages, setMessages] = useState('')
  const [latest, setLatest] = useState('')
  let arrayFiltered = []

  const fetchSubscriber = useCallback(() => {
    getSubscriber({
      variables: {
        email: state.user.user.email
      }
    }).then( ({data, error}) => {
      if (data?.subscriber === null) {
        return
      }

      const { company, designation, country, city, language, profile, stw_vision, stw_outcome } = data.subscriber
      if(!company || !designation || !country || !city || !language || !profile || !stw_vision || !stw_outcome) {
        history.push('/update')
      }
    })
  }, [getSubscriber, history, state.user.user.email])

  const fetchCourses = useCallback(() => {
    // if (state.courses.courses.length === 0) {
      setLoading(true)
      const limit = 30
      
      getCourses({
        variables: {
          limit
        }
      }).then( ({data, error}) => {
        if(error) {
          setMessages('Error fetching courses. Please try again.')
          setOpen(true)
        } else {
          dispatch({
            type: 'FETCH_ALL_COURSES',
            payload: data.courses
          })
          setLoading(false)
        }
      })
    // }
  }, [getCourses, dispatch])

  const fetchInstructors = useCallback(() => {
    if (state.instructors.instructors.length === 0) {
      setLoading(true)
      const skip = 0
      const limit = 10
      
      getInstructors({
        variables: {
          skip,
          limit
        }
      }).then( ({data, error}) => {
        if(error) {
          setMessages('Error fetching courses. Please try again.')
          setOpen(true)
        } else {
          dispatch({
            type: 'FETCH_INSTRUCTORS',
            payload: data.instructors
          })
          setLoading(false)
        }
      })
    }
  }, [getInstructors, dispatch])

  useEffect(() => {
    fetchSubscriber()
  }, [fetchSubscriber])

  useEffect(() => {
    fetchCourses()
  }, [fetchCourses])

  useEffect(() => {
    fetchInstructors()
  }, [fetchInstructors])
  
  useEffect(() => {
    const userCourses = state.courses.courses.filter(item => state.user.user.courses.includes(item._id))
    setCourses(userCourses)

    const result = state.courses.courses.reduce((a, b) => a.tm_published > b.tm_published ? a : b, {tm_updated: ""})
    setLatest(result)

    state.user.user.course_progress.forEach(obj => {
      const item = arrayFiltered.find(thisItem => thisItem.courseId === obj.courseId);
      if (item) {
        if (item.progress < obj.progress) {
          item.progress = obj.progress;
        }          
        return;
      }
      
      arrayFiltered.push(obj)
    })

    let merged = [];

    for(let i=0; i<state.courses.courses.length; i++) {
      merged.push({
        ...state.courses.courses[i], 
        ...(arrayFiltered.find((itmInner) => itmInner.courseId === state.courses.courses[i]._id))}
      );
    }
    setProgress(merged.filter(m => m.courseId && m.progress))
  }, [state.user.user.courses, state.user.user.course_progress, state.courses.courses])

  const handleClose = (event, reason) => {
    setOpen(false)
  }

  return (
    <div>
      <div style={{marginTop: '65px'}}>
        <Banner data={state.courses.courses.filter(b => b.is_featured === true)} />
      </div>

      <div className={styles.content}>
        {loading ? <ContentPlaceholder /> : <MovieStrip data={state.courses.courses} title="Courses to get you started" />}
        {progress && progress.length > 0 ? <MovieStrip data={progress} title="Continue watching" /> : ''}
        {courses && courses.length > 0 ? <MovieStrip data={courses} title="My Classes" /> : ''}
        
        <FeaturedLeft data={latest} />

        {loading ? <ContentPlaceholder /> : <MovieStrip data={state.courses.courses} title="More Discoveries" />}
        {loading ? <ContentPlaceholder /> : <MovieStrip data={state.courses.courses} title="Trending" />}

        {/*<CourseDialog selectedValue={selectedValue} open={open} onClose={handleClose} />*/}

        <SnackBar messages={messages} open={open} close={handleClose} />
      </div>
    </div>
  )
}

export default memo(BrowseList)