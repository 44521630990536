import React, { memo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Container, Typography } from '@material-ui/core'
import StripeCheckoutForm from './components/Stripe/StripeCheckoutForm'
import signupStyle from './style'
import './signup.css'

const SignUpCheckout = () => {
  const classes = signupStyle()
  const history = useHistory()
  const signup = useSelector(state => state.signup)

  useEffect(() => {
    if(!signup.email) {
      history.push('/signup/account')
    }
  }, [signup.email, history])

  return (
    <div className={classes.signUpWrapper}>
      <Container className={classes.root}>
        <Typography variant="caption">Step 4 of 4</Typography>
      	<Typography variant="h5">Start your subscription</Typography>
        <Typography variant="body2" gutterBottom>Just a few more steps and you're done! We hate paperwork, too.</Typography>

        <div className={classes.center}>
          <div className={classes.checkout}>
            <div className={classes.stepContext}>
            	<StripeCheckoutForm email={signup.email} returnUrl="/login" />
            </div>
          </div>
        </div>
      </Container>

      
    </div>
  )
}

export default memo(SignUpCheckout)