import React, { memo, useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useMutation } from 'graphql-hooks'
import { makeStyles } from '@material-ui/core/styles'
import {
	Button,
  Container,
  CssBaseline,
  Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
  Grid,
  TextField,
  Typography
} from '@material-ui/core'
import { SUBSCRIBER_QUERY, USER_UPDATE_PROFILE_MUTATION } from '@gql/user'
import {
  Spinner
} from '@common/components'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),

    '& .MuiButton-contained.Mui-disabled': {
    	backgroundColor: '#009688'
    }
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textField: {
    border: 'none',
    borderRadius: '0',
    outline: 'none',

    '& .MuiOutlinedInput-root': {
			backgroundColor: '#ffffff',
    },

    '& fieldset': {
      border: 'none'
    },

    '&:hover fieldset': {
      border: 'none'
    },

    // [theme.breakpoints.down('sm')]: {
    //   width: '100%'
    // },
  },
  raleway: {
		fontFamily: `"Raleway", "sans-serif"`,

		[theme.breakpoints.down('sm')]: {
      fontSize: '1.1rem'
    },
	}
}));

const UpdateProfile = () => {
	const styles = useStyles()
	const history = useHistory()
	const dispatch = useDispatch()
	const user = useSelector(state => state.user)
	const [getSubscriber] = useMutation(SUBSCRIBER_QUERY)
	const [updateProfile] = useMutation(USER_UPDATE_PROFILE_MUTATION)
	const [open, setOpen] = useState(false)
	const [loading, setLoading] = useState(false)
	// const [messages, setMessages] = useState('')
	const [values, setValues] = useState({
		email: '',
    fullname: '',
    mobile: '',
    plan_type: '',
    main_account: '',
    company: '',
    designation: '',
    country: '',
    city: '',
    language: '',
    profile: '',
    stw_vision: '',
    stw_outcome: '',
    favourite_categories: [],
  })

  const fetchSubscriber = useCallback(() => {
    getSubscriber({
      variables: {
        email: user.user.email
      }
    }).then( ({data, error}) => {
      setValues(data.subscriber)
    })
  }, [getSubscriber, user.user.email])

  useEffect(() => {
  	fetchSubscriber()
  }, [fetchSubscriber])

	const handleInputChange = e => {
    const {name, value} = e.target
    setValues({...values, [name]: value})
  }

	const userUpdateProfile = () => {
		const {
			fullname,
			company,
			designation,
			country,
			city,
			language,
			profile,
			stw_vision,
			stw_outcome
		} = values

		setLoading(true)

		updateProfile({
			variables: {
				email: user.user.email,
				profile: {
					fullname,
					company,
					designation,
					country,
					city,
					language,
					profile,
					stw_vision,
					stw_outcome
        }
      }
    }).then( ({data, error}) => {
      if (data?.register === null) {
        // const { message } = error.graphQLErrors[0]
        // setMessages(message)
        setLoading(false)
        return
      }

      setLoading(false)
      history.push('/user/profile')
    })
  }

  // const handleLogout = () => {
  //   setOpen(true)
  // }

  const handleClose = () => {
    setOpen(false)
  }

  const handleUserLogout = () => {
    dispatch({
      type: 'USER_LOGOUT'
    })
  }

	return (
		<div className={styles.root}>
			<Container>
				<CssBaseline />
				<div className={styles.paper}>
					<Typography component="h1" variant="h5" className={styles.raleway}>Please update your profile to start browsing</Typography>
					<Typography variant="body2" className={styles.raleway}>All fields are mandatory</Typography>
					<form className={styles.form} noValidate>
						<Grid container spacing={3}>
							<Grid item xs={12} md={6}>
			          <TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									id="fullname"
									placeholder="Full name *"
									name="fullname"
									autoComplete="fullname"
									autoFocus
									onChange={handleInputChange}
									className={[styles.textField, styles.raleway]}
									value={values.fullname}
			          />
							</Grid>
							<Grid item xs={12} md={6}>
			          <TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									id="company"
									placeholder="Company *"
									name="company"
									autoComplete="company"
									autoFocus
									onChange={handleInputChange}
									className={[styles.textField, styles.raleway]}
									value={values.company}
			          />
							</Grid>
							<Grid item xs={12} md={6}>
			          <TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									id="designation"
									placeholder="Designation *"
									name="designation"
									autoComplete="designation"
									autoFocus
									onChange={handleInputChange}
									className={[styles.textField, styles.raleway]}
									value={values.designation}
			          />
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									id="country"
									placeholder="Country *"
									name="country"
									autoComplete="country"
									autoFocus
									onChange={handleInputChange}
									className={[styles.textField, styles.raleway]}
									value={values.country}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									id="city"
									placeholder="City *"
									name="city"
									autoComplete="city"
									autoFocus
									onChange={handleInputChange}
									className={[styles.textField, styles.raleway]}
									value={values.city}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									id="language"
									placeholder="Language *"
									name="language"
									autoComplete="language"
									autoFocus
									onChange={handleInputChange}
									className={[styles.textField, styles.raleway]}
									value={values.language}
								/>
							</Grid>
							<Grid item xs={12} md={12}>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									multiline
	          			rows={3}
									id="profile"
									placeholder="50-word Short Profile *"
									name="profile"
									autoComplete="profile"
									autoFocus
									onChange={handleInputChange}
									className={[styles.textField, styles.raleway]}
									value={values.profile}
								/>
							</Grid>
							<Grid item xs={12} md={12}>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									multiline
	          			rows={3}
									id="stw_vision"
									placeholder="Personal Vision for Change in the World *"
									name="stw_vision"
									autoComplete="stw_vision"
									autoFocus
									onChange={handleInputChange}
									className={[styles.textField, styles.raleway]}
									value={values.stw_vision}
								/>
							</Grid>
							<Grid item xs={12} md={12}>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									multiline
	          			rows={3}
									id="stw_outcome"
									placeholder="Desired outcomes as part of STW Changemakers *"
									name="stw_outcome"
									autoComplete="stw_outcome"
									autoFocus
									onChange={handleInputChange}
									className={[styles.textField, styles.raleway]}
									value={values.stw_outcome}
								/>
							</Grid>
	          </Grid>
	          {loading ? 
          		<Spinner />
          		:
          		<Grid container spacing={3}>
          			<Grid item xs={6} md={12}>
		          		<Button fullWidth variant="contained" color="primary" className={styles.raleway} onClick={() => userUpdateProfile()} disabled={
		          			!values.fullname ||
		          			!values.company ||
		          			!values.designation ||
		          			!values.country ||
		          			!values.city ||
		          			!values.language ||
		          			!values.profile ||
		          			!values.stw_vision ||
		          			!values.stw_outcome
		          		}>Update</Button>
	          		</Grid>
          		</Grid>
          	}
					</form>
				</div>
			</Container>

			<Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Confirm logout</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to logout? You will lost your unsave information.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button onClick={handleUserLogout} color="primary" autoFocus>Confirm</Button>
        </DialogActions>
      </Dialog>
		</div>
	)
}

export default memo(UpdateProfile);