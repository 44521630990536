import React, { memo, useState, useEffect, useRef, useCallback } from 'react'
import { jsPDF } from 'jspdf'
import { useSelector } from 'react-redux'
import { useManualQuery } from 'graphql-hooks'
import { useParams } from 'react-router-dom'
import {
  Button,
  makeStyles,
  Container,
  Grid,
  Typography,
  // Button
} from '@material-ui/core'
import { FETCH_CERTIFIFCATE_BY_SUBSCRIBER } from '@gql/course'
import { timestampToDateTime } from '@helpers'

import "./certificate.css"

const useStyle = makeStyles((theme) => ({
	content: {
    padding: '32px 0'
  },
  body: {
    padding: '56px 0',
    justifyContent: 'center'
  },
	button: {
		padding: '12px 16px',
    minWidth: '340px',
    minHeight: '48px',
    backgroundColor: '#e50914',
    fontSize: '16px',
    fontWeight: '400',
    color: '#fff',
    lineHeight: '24px',
    textAlign: 'center',
    textDecoration: 'none',
    textTransform: 'uppercase',
    outline: 'none',
    border: 'none',
    alignSelf: 'center',
    cursor: 'pointer',

    "&:hover": {
      backgroundColor: '#e50914'
    }
	},
  brand: {
    margin: '0 0 32px 0',
    width: '300px'
  },
  frame: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 56px',
    backgroundColor: '#fff',
    width: '100%',
    height: '100%',
    minHeight: '500px',
    color: '#000',
    textAlign: 'center'
  },
  name: {
    margin: '56px 0'
  },
  download: {
    display: 'block',
    margin: '16px auto',
    minWidth: '400px'
  }
}))

const Certificate = () => {
  const pdfRef = useRef(null)
	const styles = useStyle()
  const { classId } = useParams()
  const courses = useSelector(state => state.courses.courses)
  const user = useSelector(state => state.user.user)
  const [getCertificateBySubscriber] = useManualQuery(FETCH_CERTIFIFCATE_BY_SUBSCRIBER)
  const [course, setCourse] = useState(null)
  const [certificate, setCertificate] = useState(null)
  const [messages, setMessages] = useState(null)

  useEffect(() => {
    const filteredCourse = courses.filter(item => item._id === classId)[0]
    setCourse(filteredCourse)
  }, [classId, courses])

  const fetchCertificateBySubscriber = useCallback(() => {
    getCertificateBySubscriber({
      variables: {
        subscriberId: user.email
      }
    }).then( ({data, error}) => {
      if (data?.getCertificateBySubscriber === null) {
        const { message } = error.graphQLErrors[0]
        setMessages(message)
        return
      }
      setCertificate(data.certificates.filter(c => c.courseId === classId)[0])
    })
  }, [getCertificateBySubscriber, user.email])

  useEffect(() => {
    fetchCertificateBySubscriber()
  }, [fetchCertificateBySubscriber])

  const handleDownload = () => {
    window.print()
    return false
    // const content = pdfRef.current;

    // const doc = new jsPDF()
    // doc.html(content, {
    //   callback: function (doc) {
    //     doc.setFontSize(33)
    //     doc.setFillColor(204, 204,204,0)
    //     doc.rect(10, 10, 150, 160, "F")
    //     doc.save('sample.pdf')
    //   }
    // })
  }

  return (
    <div>
    	<Container className={styles.content}>
        <style>
        {`@media print {header{display: none;}}`}
        </style>
	    	<div className={['centificate', styles.body].join(' ')}>
          
          <Typography variant="h5" align="center">Congratulations! You have completed the course.</Typography>
          <br/>
          {/*
					<Grid item xs={8}>
            <div className={styles.frame}>
              <img src="/assets/logo4.png" alt="" className={styles.brand} />
              <Typography variant="h3">Certificate of Completion</Typography>
              <Typography variant="h5">This certificate is proudly present to</Typography>
              <Typography variant="h4" className={styles.name}>{user.fullname}</Typography>
              <Typography variant="h5">of successfully completed {course?.name}</Typography>
              <Typography variant="body2">{course?.detail}</Typography>
            </div>
					</Grid>
					<Grid item xs={4}>
            <Typography variant="subtitle1">TThis certificate verifies that {user.fullname} successfully completed the course {course?.name} as taught by {course?.instructor.fullname}. The certificate indicates the entire course was completed as validated by the student. The course duration represents the total video hours of the course at time of most recent completion.</Typography>
						<br/>
            <Typography variant="body2" gutterBottom>Certificate Recipient</Typography>
						<Typography variant="h5">{user.fullname}</Typography>
						<br/>
            <Typography variant="body2" gutterBottom>About the course</Typography>
            <Typography variant="h5">{course?.name}</Typography>
            <br/>
            <Typography variant="body2" gutterBottom>Instructor</Typography>
            <Typography variant="h5">{course?.instructor.fullname}</Typography>
					</Grid>
          */}
					{/*
          <Grid container spacing={0} direction="column" alignItems="center" justify="center">
						<Button variant="contained" color="primary" className={styles.button}>Download certificate</Button>
					</Grid>
          */}
          
            <table cellSpacing="0" cellPadding="0" style={{display: 'block', margin: '0 auto', width: '960px', height: '739px', border:0, borderSpacing: 0, color: '#000'}}>
              <tbody>
                <tr >
                  <td rowSpan="4" style={{padding: 0, background: `url('/assets/certificate_01.jpg')`, backgroundSize: 'cover', border: 0, width: '268px', height: '739px'}}></td>
                </tr>
                <tr style={{padding: 0, width: '693px', height: '148px', border: 0}}>
                  <td style={{padding: 0, background: `url('/assets/certificate_02.jpg')`, width: '693px', height: '148px', border: 0}}></td>
                </tr>
                <tr style={{padding: 0, width: '693px', height: '165px', border: 0}}>
                  <td style={{padding: 0, background: `url('/assets/certificate_03.jpg')`, width: '693px', height: '165px', border: 0}} valign="top">
                    <Typography variant="body1" style={{fontWeight: '600', fontSize: '1.3rem'}}>Certificate of Completion</Typography>
                    <Typography variant="body1" style={{fontWeight: '600', fontSize: '1.3rem'}} gutterBottom>Congratulations, {user.fullname}</Typography>
                    <br/>
                    <Typography variant="h6" style={{fontWeight: '600', lineHeight: '1.3'}}>{course?.name}</Typography>
                    <Typography variant="h6" style={{fontWeight: '600'}}>Course completed on {timestampToDateTime(certificate?.tm_awarded)}</Typography>
                  </td>
                </tr>
                <tr style={{padding: 0, width: '693px', height: '427px', border: 0}}>
                  <td style={{padding: 0, background: `url('/assets/certificate_04.jpg')`, width: '693px', height: '427px', border: 0}}></td>
                </tr>
              </tbody>
            </table>
            
            <Button variant="contained" color="primary" onClick={handleDownload} className={styles.download}>Download</Button>
        </div>
			</Container>
    </div>
  )
}

export default memo(Certificate)