import React, { memo, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import {
  Button,
  Card,
  CardContent,
  Container,
  List,
  ListItem,
  Typography
} from '@material-ui/core'
import { Check, Close } from '@material-ui/icons'
import { NavLink, useHistory } from 'react-router-dom'
import signupStyle from './style'
import './table.css'
import './signup.css'

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  planOption: {
    display: 'flex',
    justifyContent: 'center'
  },
  plan: {
    marginRight: '16px',
    marginLeft: '16px',
    padding: '0',
    maxWidth: 275,
    cursor: 'pointer',

    "& .selected": {
      position: 'absolute',
      top: '12px',
      right: '12px',
      padding: '4px',
      backgroundColor: '#00916e',
      opacity: 0,
      borderRadius: '50%',
    },

    "& .MuiCard-root": {
      background: '#000',    
      width: '100%',
      color: '#fff',
      border: '1px solid #282828',
    },

    "& .MuiTypography-body2": {
      display: 'flex',
      alignItems: 'center',

      "& svg": {
        marginRight: '12px'
      }
    },

    "&.Mui-selected": {
      "& .MuiCard-root": {
        border: '1px solid #00916e',
      },

      "& .selected": {
        opacity: 1
      },
    }
  }
});


const SignUpPlan = () => {
  const history = useHistory()
	const classes = signupStyle()
  const styles = useStyles()
  const signup = useSelector(state => state.signup)
  const [selectedIndex, setSelectedIndex] = useState(0)

  useEffect(() => {
    if(!signup.email) {
      history.push('/signup/account')
    }
  }, [signup.email, history])

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index)
  }

  return (
    <div className={classes.signUpWrapper}>
      <Container className={classes.root}>
        <Typography variant="caption">Step 3 of 3</Typography>
      	<Typography variant="h5">Choose the plan that's right for you</Typography>
        <Typography variant="body2" gutterBottom>Downgrade or upgrade at anytime</Typography>
        
        <div className={classes.stepContext}>
          <List component="div" className={styles.planOption}>
            <ListItem className={styles.plan} selected={selectedIndex === 0} onClick={(event) => handleListItemClick(event, 0)}>
              <Card>
                <CardContent>
                  <Typography variant="h5" component="h2" gutterBottom>FREE <Check className="selected" /></Typography>
                  <Typography variant="body2" component="p" gutterBottom><Check /> Individual user</Typography>
                  <Typography variant="body2" component="p" gutterBottom><Check /> One device at a time</Typography>
                  <Typography variant="body2" component="p" gutterBottom><Check /> Free Classes</Typography>
                  <Typography variant="body2" component="p" gutterBottom><Check /> Cancel anytime</Typography>
                  <Typography variant="body2" component="p" gutterBottom><Close /> Premium Classes for Consulus Consultants only</Typography>
                  <Typography variant="body2" component="p" gutterBottom><Close /> Unlimited Classes</Typography>
                  <Typography variant="body2" component="p" gutterBottom><Check /> Able to sign up for UNIFY Certification programs for Shape the World Changemakers</Typography>
                  <Typography variant="h3">USD0.00</Typography>
                </CardContent>
              </Card>
            </ListItem>
            <ListItem className={styles.plan} selected={selectedIndex === 1} onClick={(event) => handleListItemClick(event, 1)}>
              <Card>
                <CardContent>
                  <Typography variant="h5" component="h2" gutterBottom>PREMIUM <Check className="selected" /></Typography>
                  <Typography variant="body2" component="p" gutterBottom><Check /> Individual user</Typography>
                  <Typography variant="body2" component="p" gutterBottom><Check /> One device at a time</Typography>
                  <Typography variant="body2" component="p" gutterBottom><Check /> Free Classes</Typography>
                  <Typography variant="body2" component="p" gutterBottom><Check /> Cancel anytime</Typography>
                  <Typography variant="body2" component="p" gutterBottom><Check /> Premium Classes for Consulus Consultants only</Typography>
                  <Typography variant="body2" component="p" gutterBottom><Check /> Unlimited Classes</Typography>
                  <Typography variant="body2" component="p" gutterBottom><Check /> Able to sign up for UNIFY Certification programs for Consulites</Typography>
                  <Typography variant="h3">USD0.00</Typography>
                  <Typography variant="body2" component="p" gutterBottom><i>100% subsidy for one year for Consulites. Promo code applies.</i></Typography>
                </CardContent>
              </Card>
            </ListItem>
          </List>

          {/*}
          <table>
            <colgroup></colgroup>
            <colgroup></colgroup>
            <colgroup></colgroup>
            <colgroup></colgroup>

            <thead>
              <tr>
                <th>&nbsp;</th>
                <th><h2>Free</h2></th>
                <th><h2>Premium</h2></th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <th width="60%" align="left"><Typography variant="body1">Monthly price</Typography></th>
                <td width="20%" align="center"><Typography variant="h6">Free</Typography></td>
                <td width="20%" align="center"><Typography variant="h6">SGD 99</Typography></td>
              </tr>
              <tr>
                <th align="left"><Typography variant="body1">Free classes</Typography></th>
                <td align="center"><Check /></td>
                <td align="center"><Check /></td>
              </tr>
              <tr>
                <th align="left"><Typography variant="body1">Featured classes</Typography></th>
                <td align="center"><Close /></td>
                <td align="center"><Check /></td>
              </tr>
              <tr>
                <th align="left"><Typography variant="body1">Unlimited classes</Typography></th>
                <td align="center"><Close /></td>
                <td align="center"><Check /></td>
              </tr>
              <tr>
                <th align="left"><Typography variant="body1">Family account</Typography></th>
                <td align="center"><Close /></td>
                <td align="center"><Check /></td>
              </tr>
              <tr>
                <th align="left"><Typography variant="body1">Cancel anytime</Typography></th>
                <td align="center"><Check /></td>
                <td align="center"><Check /></td>
              </tr>
            </tbody>
          </table>
          */}
        </div>

        <div className={classes.stepAction}>
          <div className={classes.stepTerms}>
            <Typography variant="body2">See <span>Terms of Use</span> for more details</Typography>
          </div>
          {selectedIndex === 0 ? <Button variant="contained" color="primary" className={classes.button} component={NavLink} to="/login">Proceed to login</Button> : <Button variant="contained" color="primary" className={classes.button} component={NavLink} to="/signup/checkout">Checkout</Button>}
          {/*<NavLink to='/signup/checkout' className={classes.button}>Continue</NavLink>*/}
        </div>
      </Container>
    </div>
  )
}

export default memo(SignUpPlan)